import React, { useRef, useState, useEffect } from "react";
import './ScrollableLibraryContainer.css';
import LibraryEntry from "./LibraryEntry.jsx";

const ScrollableLibraryContainer = ({ items }) => {
  const containerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const { current } = containerRef;
    if (current) {
      const handleScroll = () => {
        setCanScrollLeft(current.scrollLeft > 0);
        setCanScrollRight(current.scrollLeft < (current.scrollWidth - current.clientWidth));
      };

      // Call handleScroll to check initial scroll position
      handleScroll();

      current.addEventListener("scroll", handleScroll);
      return () => {
        current.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleScrollLeft = () => {
    const { current } = containerRef;
    if (current) {
      current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const handleScrollRight = () => {
    const { current } = containerRef;
    if (current) {
      current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <div className="scrollableLibraryContainer">
      <div className="libraryItems" ref={containerRef}>
        {items.map((item) => (
          <LibraryEntry key={item.id} item={item}/>
        ))}
      </div>
      <div className="scrollButtonContainer">
        {canScrollLeft && (
          <button
            className="scrollButton scrollButtonLeft"
            onClick={handleScrollLeft}
          >
            ←
          </button>
        )}
        {canScrollRight && (
          <button
            className="scrollButton scrollButtonRight"
            onClick={handleScrollRight}
          >
            →
          </button>
        )}
      </div>
    </div>
  );
};

export default ScrollableLibraryContainer;
