import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ModelDiagramPreview from './ModelDiagramPreview';
import { useParams } from 'react-router-dom';
import { deleteDiagram, selectModelData } from '../../Statemanagement/modelSlice';
import UserLogout from '../LandingPage/UserLogout';
import NewDiagramButton from '../Infrastructure/NewDiagramButton';

const ModelOverview = () => {
  const { id } = useParams();
  const dispatch = useDispatch(); // Get dispatch function from Redux
  const [error, setError] = useState(null);
  const modelData = useSelector(selectModelData); // Accessing modelData from Redux store
  const [deletedDiagramId, setDeletedDiagramId] = useState(null); // State variable to force re-render


  const handleDeleteDiagram = (diagramId) => {
    // Dispatch the deleteDiagram action
    dispatch(deleteDiagram({diagramId}));
    setDeletedDiagramId(diagramId);
  };

  useEffect(() => {
    // Any logic you may want to run on deletion and re-render
    // For example, fetching updated modelData from Redux
  }, [deletedDiagramId]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <div className='space-title-container'>
        <h1>{modelData.name}</h1>
      </div>
      <div className="top-right-menu">
        <NewDiagramButton />
        <UserLogout />
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {modelData &&
          Object.keys(modelData.diagrams).map((elementKey) => (
            <ModelDiagramPreview
              key={elementKey}
              element={modelData.diagrams[elementKey]}
              model={id}
              onDeleteDiagram={handleDeleteDiagram} // Pass onDeleteDiagram callback
            />
          ))}
      </div>
    </div>
  );
};

export default ModelOverview;
