import React, { useState, useEffect, useRef } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand , faLock } from '@fortawesome/free-solid-svg-icons';

function DiagramViewToolbar({ windowOrigin, setCanvasOrigin , setAllowPan, allowPan}) {
  //Function to handle shape button clicks
  const handleCenterButtonClick = (event) => {
    const newCanvasOrigin = { x: windowOrigin.x, y: windowOrigin.y };
    setCanvasOrigin(newCanvasOrigin);
  };

  const handleLockButtonClick = (event) => {
    if(allowPan){
      setAllowPan(false);
      handleCenterButtonClick();
    }else{
      setAllowPan(true);
    }
  };

  useEffect(() => {  
    const handleKeyDown = (event) => {
      //if(event.key === 'l') {
      //  handleLockButtonClick();
      //ll}
      if(event.key === 'c') {
        handleCenterButtonClick();
      }
    };

    // Attach event listener when component mounts
    document.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount



  return (
    <div class="edit-toolbar">
        <button id="centerButton" className="toolbar-button"
        onMouseDown={handleCenterButtonClick}><FontAwesomeIcon icon={faExpand} size="2x" /></button>
        <button id="lockButton" className={`toolbar-button ${allowPan ? '' : 'selected'}`}
        onMouseDown={handleLockButtonClick}><FontAwesomeIcon icon={faLock} size="2x" /></button>
    </div>
  );
}

export default DiagramViewToolbar;