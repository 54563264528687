import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Menu/Home';
import DiagramEditor from './components/DiagramTools/DiagramEditor';
import NotationOverview from './components/Menu/Notations/NotationOverview';
import ModelOverview from './components/Menu/Models/ModelOverview';
import FrameworkOverview from './components/Menu/Frameworks/FrameworkOverview';
import './App.css';
import LandingPage from './components/Menu/LandingPage/LandingPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/" element={<LandingPage/>} />
          <Route path="/notation/:id" element={<NotationOverview />} />
          <Route path="/framework/:id" element={<FrameworkOverview />} />
          <Route path="/model/:id" element={<ModelOverview />} />
          <Route path="/model/:modelId/:type/:diagramId" element={<DiagramEditor />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
