import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: apiUrl, // Set the base URL to your desired server address
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
