import { combineReducers } from 'redux';
import modelReducer from './modelSlice';
import menuReducer from './menuSlice';
import diagramReducer from './diagramSlice';
import { ADD_ELEMENT_TO_DIAGRAM } from './actionTypes'; // Adjust the path as needed
import { CREATE_DIAGRAM } from './actionTypes'; // Adjust the path as needed
import { SET_ACTIVE_MODEL } from './actionTypes'; // Adjust the path as needed
import { SET_ACTIVE_DIAGRAM } from './actionTypes'; // Adjust the path as needed
import { CREATE_CONCEPT } from './actionTypes'; // Adjust the path as needed
import { SET_ACTIVE_CONCEPT } from './actionTypes'; // Adjust the path as needed
import { ADD_NOTATION } from './actionTypes'; // Adjust the path as needed
import { ADD_FRAMEWORK } from './actionTypes'; // Adjust the path as needed
import { ADD_MODEL } from './actionTypes'; // Adjust the path as needed 
import { SET_MENU_ITEMS } from './actionTypes'; // Adjust the path as needed

const initialState = {
  activeDiagram: {
    diagramId: null,
    elementTypes: [],
    // Other properties of the active diagram
  },

  concepts: {
    conceptId: null
  }
  // Other parts of the state
};


// Reducer for the element database
const elementDatabaseReducer = (state = [], action) => {
  switch (action.type) {
    // Handle actions to add, update, or delete elements from the database
    default:
      return state;
  }
};

// Reducer for individual diagrams
const diagramsReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ELEMENT_TO_DIAGRAM:
      const { diagramId: addElementId, elementType, elementId, elementInfo } = action.payload;
      // Ensure that the diagram state is initialized with an empty object if it's undefined
      const addElementDiagramState = state[addElementId] || { diagramElements: {} };
      
      // Add the new element to the diagramElements object directly with its elementId, elementType, and elementInfo
      const updatedDiagramElements = {
        ...addElementDiagramState.diagramElements,
        [elementId]: { elementType, elementInfo }
      };
    
      return {
        ...state,
        [addElementId]: {
          ...addElementDiagramState,
          diagramElements: updatedDiagramElements
        },
      };
    case CREATE_DIAGRAM:
      const { diagramId: createDiagramId, diagramType } = action.payload; // Rename diagramId to createDiagramId
      // Ensure that the diagram state is initialized with an empty object if it's undefined
      return {
        ...state,
        [createDiagramId]: {
          diagramElements: [], // Initialize elements array for new diagram
          diagramType: diagramType // Optionally store diagram type
        },
      };
    // Handle other actions
    default:
      return state;
  }
};

// Reducer for the current diagram
const currentModelReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_MODEL:
      const { modelId: activeModelId, modelType: activeModelType, modelName: activeModelName, modelFrameworks: activeModelFrameworks, modelElements: activeModelElements, modelDiagrams: activeModelDiagrams } = action.payload;
      return {
        ...state,
        activeModelId,
        activeModelType,
        activeModelName,
        activeModelFrameworks,
        activeModelElements,
        activeModelDiagrams
         // Change the active diagram to the new diagramId
      };
    default:
      return state;
  }
};

// Reducer for the current diagram
const currentDiagramReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_DIAGRAM:
      const { diagramId: activeDiagramId, elementTypes } = action.payload;
      return {
        ...state,
        activeDiagramId,
        elementTypes // Change the active diagram to the new diagramId
      };
    default:
      return state;
  }
};

// Reducer for individual diagrams
const conceptReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CONCEPT:
      const { conceptId: createConceptId } = action.payload; // Rename diagramId to createDiagramId
      const name = "henry"
      // Ensure that the diagram state is initialized with an empty object if it's undefined
      return {
        ...state,
        [createConceptId]: {name
        },
      };
    // Handle other actions
    default:
      return state;
  }
};

// Reducer for the current diagram
const currentConceptReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_ACTIVE_CONCEPT:
      const { conceptId: activeConceptId } = action.payload;
      return {
        ...state,
        activeConceptId,
      };
    default:
      return state;
  }
};

const notationsReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_NOTATION:
      const { className } = action.payload;
      return [...state, className]; // Add the new class to the end of the array
    default:
      return state;
  }
};

const modelsReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_MODEL:
      const { className } = action.payload;
      return [...state, className]; // Add the new class to the end of the array
    default:
      return state;
  }
};

const frameworksReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_FRAMEWORK:
      const { className } = action.payload;
      return [...state, className]; // Add the new class to the end of the array
    default:
      return state;
  }
};


// Combine reducers
const rootReducer = combineReducers({
  model: modelReducer,
  //elementDatabase: elementDatabaseReducer,
  //diagrams: diagramsReducer,
  //concepts: conceptReducer,
  //activeModel: currentModelReducer,
  //activeDiagram: currentDiagramReducer,
  //activeConcept: currentConceptReducer,
  //notations: notationsReducer,
  //models: modelsReducer,
  //frameworks: frameworksReducer,
  menu: menuReducer,
  diagram: diagramReducer,

});

export default rootReducer;
