import React, { useRef, useEffect } from 'react';
import ContentTable from './ContentTable';

const FrameworkDiagramPreview = ({ element: framework , stakeholder, concerns, ontology, viewpoints}) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw a white background
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  
    }, [framework]);
  

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column', // Stack elements vertically
      alignItems: 'center',
      margin: 20,
      backgroundColor: '#333',
      borderRadius: 10,
      fontSize: 12,
    }}>
      <div>
      <canvas
        ref={canvasRef}
        width={500}
        height={300}
        style={{
            border: '1px solid black',
            backgroundColor: 'white', // White background for the canvas
            width: '250px',  // Adjust the width in CSS to maintain aspect ratio
            height: '150px', // Adjust the height in CSS to maintain aspect ratio
            borderRadius: 10,
        }}
        />
      </div>
      <div style={{
          display: 'flex',
          flexDirection: 'column', // Stack elements vertically
          alignItems: 'center',
        }}>
        <h3>{framework}</h3>
        {framework === 'Context' && (
          <div style={{
            display: 'flex',
            flexDirection: 'column', // Stack elements vertically
            alignItems: 'center',
          }}>
            <ContentTable title="Stakeholder" content={stakeholder}/>
            <ContentTable title="Concerns" content={concerns}/>
          </div>
        )}
        {framework === 'Ontology' && (
          <div>
            <ContentTable title="Concepts" content={ontology}/>
          </div>
        )}
        {framework === 'Viewpoints' && (
          <div>
            <ContentTable title="Viewpoints" content={viewpoints}/>
          </div>
        )}
      </div>
    </div>
  );  
};

export default FrameworkDiagramPreview;
