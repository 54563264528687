import React, { useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setDiagramInfo, deleteDiagram } from '../../DiagramTools/modelUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { Button } from "../../ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog"

const ModelDiagramPreview = ({ element: diagram, model: modelid, onDeleteDiagram }) => {
  const canvasRef = useRef(null);
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState(false);
  const [showInputField, setShowInputField] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw a white background
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }, [diagram]);

  const handleLinkClick = async () => {
    const diagramId = diagram.id;
    const diagramName = diagram.name;
    const diagramNotation = diagram.notation;
    const diagramType = diagram.type;
    await setDiagramInfo(diagramId, diagramName, diagramNotation, diagramType);

    // After setting the diagram info, navigate to the editor
    navigate(`/model/${modelid}/${diagram.type}/${diagram.id}`, {
      state: {
        diagramId: diagram.id,
        diagramName: diagram.name,
        diagramNotation: diagram.notation,
        diagramType: diagram.type,
        modelId: modelid,
      },
    });
  };

  const handleEditButtonClick = () => {
    // Show input field for editing diagram name
    setShowInputField(true);
  };

  const handleDeleteButtonClick = () => {
    // Implement logic to delete the diagram from the model
    deleteDiagram(modelid, diagram.id)
      .then(() => onDeleteDiagram(diagram.id))  // Call onDeleteDiagram callback on success
      .catch((error) => console.error('Error deleting diagram:', error));
  };

  const handleInputChange = (event) => {
    // Handle input field change (for editing diagram name)
    const newName = event.target.value;
    // Implement logic to update diagram name
    console.log('New diagram name:', newName);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 20,
        backgroundColor: '#0F172A',
        borderRadius: 10,
        fontSize: 12,
        position: 'relative', // Add position relative for absolute positioning of buttons
        padding: 4,
        color: 'white'
      }}
      onMouseEnter={() => setShowButtons(true)}
      onMouseLeave={() => setShowButtons(false)}
    >
      <div>
        <div
          className="link-text"
          onClick={handleLinkClick}
          style={{
            cursor: 'pointer',
          }}
        >
          <canvas
            ref={canvasRef}
            width={500}
            height={300}
            style={{
              border: '1px solid black',
              backgroundColor: 'white',
              width: '250px',
              height: '150px',
              borderRadius: 10,
            }}
          />
        </div>
      </div>
      <div
        style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative', // Ensure absolute positioning is relative to this container
            width: '100%',
            padding: '10px',
        }}
        >
        {/* Display input field if editing is enabled */}
        {showInputField ? (
            <input
            type="text"
            value={diagram.name}
            onChange={handleInputChange}
            onBlur={() => setShowInputField(false)} // Hide input field on blur
            style={{ marginRight: '10px' }}
            />
        ) : (
            <h3 style={{ margin: '0' }}>{diagram.type} | {diagram.name}</h3>
        )}
        </div>
        {/* Buttons for editing and deleting */}
            {showButtons && (
        <div style={{ position: 'absolute', right: '15px', bottom: '70px', transform: 'translateY(50%)', backgroundColor: 'transparent' }}>
          <Dialog>
            <DialogTrigger asChild>
              <Button 
                type="submit"
                variant='default' 
                size='icon'
              >
                <FontAwesomeIcon icon={faTrashAlt} size="1x"/>
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-xs" width='350px'>
              <DialogHeader>
                <DialogTitle>Are you sure you want to delete diagram "{diagram.name}"?</DialogTitle>
              </DialogHeader>
              <DialogFooter className="sm:justify-start">
                <DialogClose asChild>
                  <Button 
                    type="submit"
                    onClick={handleDeleteButtonClick}>
                    Delete
                  </Button>
                </DialogClose>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
        )}
    </div>
  );
};

export default ModelDiagramPreview;
