import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from '../../../axiosConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faRocket } from '@fortawesome/free-solid-svg-icons';
import { setModelData } from '../../Statemanagement/modelSlice';

import { Button } from "../../ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog"
import { Input } from "../../ui/input"

function GoToAppButton() {
  const [modelId, setModelId] = useState(null);
  const navigate = useNavigate(); // Hook to access the navigate function
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [authenticated, setAuthenticated] = useState(false);


  const handleFetchModel = async () => {
    try {
      const response = await axios.get('/api/model', { withCredentials: true });
      const modelId = response.data._id;
      setModelId(modelId);
      dispatch(setModelData(response.data));
      // Navigate to the model page after setting the model ID
      navigate(`/model/${modelId}`);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        console.log('Unauthorized access. Redirecting to login page...');
        // Implement redirection to login or display a message to the user
      } else {
        console.error('Error fetching model:', err);
      }
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/login', { username, password }, { withCredentials: true });
      if (response.data.message === 'Logged in successfully') {
        setAuthenticated(true);
        setUsername('');
        setPassword('');
        handleFetchModel();
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setError('Invalid username or password');
      } else {
        setError('Login failed. Please try again.');
        console.error('Error logging in:', err);
      }
    }
  };


  return (
    <div className='go-to-app-box'>
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='secondary' size='lg'>go to space <FontAwesomeIcon icon={faRocket} size='1x' style={{ marginLeft: '20' }}/></Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xs">
        <DialogHeader>
          <DialogTitle>User Login</DialogTitle>
          <DialogDescription>
            put in your credentials
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-4">
            <Input
              id="username"
              placeholder="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              id="password"
              placeholder="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter className="sm:justify-start">
          <DialogClose asChild>
            <Button type="submit" onClick={handleLogin}>
              Login
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
    </div>
  )
}

export default GoToAppButton;