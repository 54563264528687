import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

import { updateDiagramModel } from '../DiagramTools/modelUtils';

import './HomeLink.css';

function HomeLink({ path, modelId, diagramId }) {

    const navigate = useNavigate();

    const handleButtonClick = async () => {
        try {
            // Call the updateDiagramModel function to update the model
            await updateDiagramModel(modelId, diagramId);

            // Navigate to the specified path after the update is complete
            navigate(path);
        } catch (error) {
            console.error('Error updating diagram model:', error);
            // Optionally, handle the error (e.g., show an error message)
        }
    };

    return (
        <div className="home-box">
        <button 
            id="homeBtn" 
            className="home-button"
            onClick={handleButtonClick}><FontAwesomeIcon icon={faHouse} size="2x" />
        </button>
        </div>
    );
  }

export default HomeLink;