import { createSlice } from '@reduxjs/toolkit';

export const modelSlice = createSlice({
  name: 'model',
  initialState: {
    id: null,
    type: null,
    name: null,
    framework: [],
    elements: [],
    diagrams: []
  },
  reducers: {
    setModelData: (state, action) => {
      const { _id, id, type, name, framework, elements, diagrams } = action.payload;
      state._id = _id;
      state.id = id;
      state.type = type;
      state.name = name;
      state.framework = framework;
      state.elements = elements;
      state.diagrams = diagrams;
    },
    deleteDiagram: (state, action) => {
      const { diagramId } = action.payload;
      state.diagrams = state.diagrams.filter(diagram => diagram.id !== diagramId);
    },
    selectElement: (state, action) => {
      const { diagramId, elementId } = action.payload;
      
      state.diagrams = state.diagrams.map(diagram => {
        if (diagram.id === diagramId) {
          return {
            ...diagram,
            elements: diagram.elements.map(element => 
              element.id === elementId 
                ? { ...element, state: "selected" } 
                : { ...element, state: "default" }
            ),
          };
        }
        return diagram; // Keep other diagrams unchanged
      });
    },
    selectConnection: (state, action) => {
      const { diagramId, connectionId } = action.payload;
      
      state.diagrams = state.diagrams.map(diagram => {
        if (diagram.id === diagramId) {
          return {
            ...diagram,
            connections: diagram.connections.map(connection => 
              connection.id === connectionId 
                ? { ...connection, state: "selected" } 
                : { ...connection, state: "default" }
            ),
          };
        }
        return diagram; // Keep other diagrams unchanged
      });
    },
    hoverElement: (state, action) => {
      const { diagramId, elementId } = action.payload;
      
      state.diagrams = state.diagrams.map(diagram => {
        if (diagram.id === diagramId) {
          return {
            ...diagram,
            elements: diagram.elements.map(element => 
              element.id === elementId 
                ? { ...element, state: "hover" } 
                : { ...element, state: "default" }
            ),
          };
        }
        return diagram; // Keep other diagrams unchanged
      });
    },

    hoverConnector: (state, action) => {
      const { diagramId, elementId, connectorId } = action.payload;
    
      // Iterate over diagrams to find the correct diagram
      state.diagrams = state.diagrams.map(diagram => {
        if (diagram.id === diagramId) {
          // Iterate over elements to find the correct element
          const updatedElements = diagram.elements.map(element => {
            if (element.id === elementId) {
              // Iterate over connectors to update the specific connector's state
              const updatedConnectors = element.connectors.map(connector => ({
                ...connector,
                state: connector.id === connectorId ? "hover" : "default"
              }));
    
              return {
                ...element,
                connectors: updatedConnectors
              };
            }
            return element; // Keep other elements unchanged
          });    
          return {
            ...diagram,
            elements: updatedElements
          };
        }
        return diagram; // Keep other diagrams unchanged
      });
    },
    deselectAllElements: (state, action) => {
      const { diagramId } = action.payload;
      const diagram = state.diagrams.find(diagram => diagram.id === diagramId);
      if (diagram) {
        diagram.elements.forEach(element => {
          element.state = "default";
        });
        diagram.connections.forEach(connection => {
          connection.state = "default";
        });
      }
    },
    dragElement: (state, action) => {
      const { diagramId, elementId, xpos, ypos } = action.payload;
      const diagram = state.diagrams.find(diagram => diagram.id === diagramId);
      if (diagram) {
        const element = diagram.elements.find(element => element.id === elementId);
        if (element) {

                // Calculate the offset before updating the position
      const offsetX = xpos - element.position.x;
      const offsetY = ypos - element.position.y;
          // Update the position of the element
          element.position.x = xpos;
          element.position.y = ypos;
          element.connectors = [
            { id: 'top', x: xpos, y: ypos - element.geo.height / 2 },
            { id: 'right', x: xpos + element.geo.width / 2, y: ypos },
            { id: 'bottom', x: xpos, y: ypos + element.geo.height / 2 },
            { id: 'left', x: xpos - element.geo.width / 2, y: ypos }
          ];
    
          // Collect all related element IDs
      const relatedElementIds = [];
      element.properties.forEach(property => {
        if (Array.isArray(property.elements)) {
          relatedElementIds.push(...property.elements);
        }
      });

      // Update positions of related elements
      relatedElementIds.forEach(relatedElementId => {
        const relatedElement = diagram.elements.find(el => el.id === relatedElementId);
        if (relatedElement) {
          // Update the position of the related element
          relatedElement.position.x += offsetX;
          relatedElement.position.y += offsetY;
          relatedElement.connectors = [
            { id: 'top', x: relatedElement.position.x, y: relatedElement.position.y - relatedElement.geo.height / 2 },
            { id: 'right', x: relatedElement.position.x + relatedElement.geo.width / 2, y: relatedElement.position.y },
            { id: 'bottom', x: relatedElement.position.x, y: relatedElement.position.y + relatedElement.geo.height / 2 },
            { id: 'left', x: relatedElement.position.x - relatedElement.geo.width / 2, y: relatedElement.position.y }
          ];
            }
          });
        }
      }
    },
    
    addElement: (state, action) => {
      const { diagramId, newElement, showName, elementGeoInfo, elementNotation, elementCase, elementProperties, selectedOwner } = action.payload;
      const diagram = state.diagrams.find(diagram => diagram.id === diagramId);
      // Initialize addedElement as an object
      const addedElement = {
        id: newElement.id,
        name: newElement.name,
        showname: showName,
        notation: elementNotation,
        elementtype: newElement.type,
        case: elementCase,
        owner: selectedOwner,
        geo: elementGeoInfo,
        position: {
          x: newElement.x,
          y: newElement.y
        },
        connectors: [
          { id: 'top', x: newElement.x, y: newElement.y - elementGeoInfo.height / 2 },
          { id: 'right', x: newElement.x + elementGeoInfo.width / 2, y: newElement.y },
          { id: 'bottom', x: newElement.x, y: newElement.y + elementGeoInfo.height / 2 },
          { id: 'left', x: newElement.x - elementGeoInfo.width / 2, y: newElement.y }
        ],
        properties: elementProperties,
      };
    
      if (diagram) {
        diagram.elements = [...diagram.elements, addedElement];
      }
    },
    deleteElement: (state, action) => {
      const { diagramId, elementId } = action.payload;
  
      // Find the diagram in the state
      const diagramIndex = state.diagrams.findIndex(diagram => diagram.id === diagramId);
      if (diagramIndex !== -1) { // Check if the diagram exists
          const diagram = state.diagrams[diagramIndex];
  
          // Find the element in the diagram
          const elementIndex = diagram.elements.findIndex(element => element.id === elementId);
          const connectionIndex = diagram.connections.findIndex(connection => connection.id === elementId);
          if (elementIndex !== -1) { // Check if the element exists
              // Remove the element from the diagram's elements array
              const updatedElements = [
                  ...diagram.elements.slice(0, elementIndex),
                  ...diagram.elements.slice(elementIndex + 1)
              ];
  
              // Filter out connections involving the element
              const updatedConnections = diagram.connections.filter(connection =>
                  connection.elementstart !== elementId && connection.elementend !== elementId
              );
  
              // Update the diagram with the new elements and connections arrays
              const updatedDiagram = {
                  ...diagram,
                  elements: updatedElements,
                  connections: updatedConnections
              };
  
              // Update the state with the updated diagram
              state.diagrams.splice(diagramIndex, 1, updatedDiagram);
          } else if (connectionIndex !== -1) { // Check if the element exists
            // Remove the element from the diagram's elements array
            const updatedConnections = [
                ...diagram.connections.slice(0, connectionIndex),
                ...diagram.connections.slice(connectionIndex + 1)
            ];

            // Update the diagram with the new elements and connections arrays
            const updatedDiagram = {
                ...diagram,
                connections: updatedConnections
            };

            // Update the state with the updated diagram
            state.diagrams.splice(diagramIndex, 1, updatedDiagram);
        }
      }
    }, 
    addDiagram: (state, action) => {
      const { diagramId, diagramName, diagramNotation, diagramType } = action.payload;
      // Initialize addedDiagram as an object
      const addedDiagram = {
        id: diagramId,
        name: diagramName,
        notation: diagramNotation,
        type: diagramType,
        elements: [],
        connections: [],
      };
      // Append the new diagram to the diagrams array in the model object
      state.diagrams = [...state.diagrams, addedDiagram];
    },
    addConnection: (state, action) => {
      const { diagramId, newConnection, newConnectionDefault } = action.payload;
      
      // Check if newConnection has all required fields
      const { elementstart, connectorstart, elementend, connectorend, id } = newConnection;
      if (!elementstart || !connectorstart || !elementend || !connectorend) {
        console.error("New connection is missing required fields:", newConnection);
        return;
      }
    
      // Find the diagram by ID
      const diagram = state.diagrams.find(diagram => diagram.id === diagramId);
      if (!diagram) {
        console.error(`Diagram with ID ${diagramId} not found.`);
        return;
      }
    
      // Create a new connection object
      const addedConnection = {
        id,
        elementstart,
        connectorstart,
        elementend,
        connectorend,
        lineType: newConnectionDefault.linetype,
        geo: newConnectionDefault.geo
      };
    
      // Add the new connection to the diagram's connections
      diagram.connections = [...diagram.connections, addedConnection];
    },
    addProperty: (state, action) => {
      const {diagramId, elementOwner, elementId, elementType} = action.payload;

      const diagram = state.diagrams.find(diagram => diagram.id === diagramId);
      if (!diagram) {
        console.error(`Diagram with ID ${diagramId} not found.`);
        return;
      }
      const owner = diagram.elements.find(element => element.id === elementOwner);
      if (!owner) {
        console.error(`Element with ID ${elementOwner} not found.`);
        return;
      }
      const property = owner.properties.find(property => property.type === elementType);
      if (!property) {
        console.error(`Property with Type ${elementType} not found.`);
        return;
      }
      property.elements = [...property.elements, elementId]
    }      
  }
});

export const { deleteDiagram, addProperty, setModelData, selectElement, selectConnection, hoverElement, hoverConnector, deselectAllElements, dragElement, addElement, deleteElement, addDiagram, addConnection} = modelSlice.actions;

export const selectModelData = (state) => state.model;

export default modelSlice.reducer;
