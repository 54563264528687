import React, { useState, useEffect } from 'react';
import TitleBox from '../TitleBox';
import GoToAppButton from './GoToAppButton';
import Footer from '../Footer';



function LandingPage() {

  const words = ["Visualize", "Share", "Discuss", "Evolve", "Formulize"];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
      const intervalId = setInterval(() => {
          setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 2000); // Change word every 2 seconds

      return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [words.length])

  return (
    <div className="home-container">
      <TitleBox />
      <div className='body'>
        <h1><span style={{ color: 'gold' }}>{words[currentWordIndex]}</span> your thoughts</h1>
        <h1>with models</h1>
        <p>Utilizing model-based collaboration</p>
        <GoToAppButton/>
      </div>
      <div className='image'>
        <img src={`${process.env.PUBLIC_URL}/PR.png`} alt="Example" />
      </div>
      <Footer/>
    </div>
  );
}

export default LandingPage;
