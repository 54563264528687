import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { Button } from "../../ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";
import { Input } from "../../ui/input";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { setDiagramInfo, setNewDiagram } from '../../DiagramTools/modelUtils';

function generateUniqueId() {
  return uuidv4();
}

const NewDiagramButton = ({ onClick, label, className, ...props }) => {
  const [newDiagramName, setNewDiagramName] = useState('');
  const [newDiagramNotation, setNewDiagramNotation] = useState('');
  const [newDiagramType, setNewDiagramType] = useState('');
  const [diagramTypesForSelectedNotation, setDiagramTypesForSelectedNotation] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  const notations = useSelector(state => state.model.framework.notations);

  const handleCancelDiagram = () => {
    setNewDiagramName('');
    setNewDiagramNotation('');
    setDiagramTypesForSelectedNotation([]);
    setErrorMessage('');
  }

  const handleAddDiagram = async () => {
    if (!newDiagramName || !newDiagramNotation || !newDiagramType) {
      setErrorMessage('selection incomplete');
      return;
    }
    

    const diagramId = generateUniqueId();
    const diagramName = newDiagramName;
    const diagramNotation = newDiagramNotation;
    const diagramType = newDiagramType;

    // Set new diagram name and wait for it to complete
    await setNewDiagram(diagramId, diagramName, diagramNotation, diagramType);

    // Now that the new diagram name is set, proceed to set diagram info
    await setDiagramInfo(diagramId, diagramName, diagramNotation, diagramType);

    // After setting the diagram info, navigate to the editor
    navigate(`/model/${id}/${diagramType}/${diagramId}`, {
      state: {
        diagramId: diagramId,
        diagramName: newDiagramName,
        diagramNotation: newDiagramNotation,
        diagramType: newDiagramType,
        modelId: id,
      }
    });
  };

  const handleDiagramNameChange = (event) => {
    setNewDiagramName(event.target.value);
  };

  const handleDiagramNotationChange = (notationId) => {
    setNewDiagramNotation(notationId);

    // Find the selected notation in the notations array
    const selectedNotation = notations.find(notation => notation.id === notationId);

    // Update the state with the selected notation's diagram types
    if (selectedNotation) {
      setDiagramTypesForSelectedNotation(selectedNotation.diagrams);
    }
  };

  const handleDiagramTypeChange = (type) => {
    setNewDiagramType(type);
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='secondary' size='default'>Add Diagram</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-xs">
        <DialogHeader>
          <DialogTitle>Create a new diagram</DialogTitle>
          <DialogDescription>
            select details
          </DialogDescription>
        </DialogHeader>
        <div className="flex items-center space-x-2">
          <div className="grid flex-1 gap-4">
            <Input
              id="diagram-name"
              placeholder="New diagram name"
              value={newDiagramName}
              onChange={handleDiagramNameChange}
            />
            <Select onValueChange={handleDiagramNotationChange} value={newDiagramNotation}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a notation" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {notations.map(notation => (
                    <SelectItem key={notation.id} value={notation.id}>
                      {notation.name}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Select onValueChange={handleDiagramTypeChange} value={newDiagramType}>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a type" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {diagramTypesForSelectedNotation.map(type => (
                    <SelectItem key={type} value={type}>
                      {type}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </div>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        <DialogFooter className="sm:justify-start">
          <DialogClose asChild>
            <Button type="cancel" onClick={handleCancelDiagram}>
              Cancel
            </Button>
          </DialogClose>
          <Button variant="secondary" type="submit" onClick={handleAddDiagram}>
              Create
            </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default NewDiagramButton;
