import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NotationElementPreview from './NotationElementPreview';
import NotationDiagramPreview from './NotationDiagramPreview';
import HomeLink from '../HomeLink';
import axios from '../../../axiosConfig';

const NotationOverview = () => {
  const [notation, setNotation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  const fetchNotationById = async (notationId) => {
    try {
      const response = await axios.get(`/api/notations/${notationId}`);
      console.log("fetched:", response)
      return response.data;
    } catch (error) {
      console.error('Error fetching notations:', error);
      throw error;
    }
  };

  useEffect(() => {
    const notationId = id;
    const fetchNotation = async () => {
      try {
        const response = await fetchNotationById(notationId);
        console.log("notations:", response)
        setNotation(response);
      } finally {
        setLoading(false);
      }
    };

    fetchNotation();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1>{notation.name}</h1>
      <h3>Notation Overview</h3>
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {notation && Object.keys(notation.elements).map(elementKey => (
          <NotationElementPreview key={elementKey} element={notation.elements[elementKey]} />
        ))}
      </div>
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {notation && Object.keys(notation.diagrams).map(elementKey => (
          <NotationDiagramPreview key={elementKey} element={notation.diagrams[elementKey]} />
        ))}
      </div>
      <HomeLink />
    </div>
  );
};

export default NotationOverview;
