import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShapes } from '@fortawesome/free-solid-svg-icons';
import '../Menu.css';
import Library from './Library';

const MainContainer = ({ title, type }) => {
    return (
        <div>
            <div className='maincontainer'>
                <div className="maincontainer-title"><FontAwesomeIcon icon={faShapes} /> {title}</div>
                <Library type={type} />
            </div>
        </div>
    );
}

export default MainContainer;
