import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ScrollableLibraryContainer from './ScrollableLibraryContainer.jsx';

function Library({ type }) {
  const content = useSelector(state => state.menu[type]);

  return (
    <div className='library-container'>
      <div className='push-button'>+</div>
      {content ? (
        <ScrollableLibraryContainer items={content} />
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}

export default Library;
