import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';

import { updateDiagramModel } from '../DiagramTools/modelUtils';

import './HomeLink.css';

function SafeButtonHomeExtesion({ modelId, diagramId }) {

    const handleButtonClick = async () => {
        try {
            // Call the updateDiagramModel function to update the model
            await updateDiagramModel(modelId, diagramId);
        } catch (error) {
            console.error('Error updating diagram model:', error);
            // Optionally, handle the error (e.g., show an error message)
        }
    };

    return (
        <div className="home-box-extention">
            <button 
                id="backBtn" 
                className="home-button"
                onClick={handleButtonClick}>
                    <FontAwesomeIcon icon={faFloppyDisk} size="1x" />
            </button>
        </div>
    );
}

export default SafeButtonHomeExtesion;
