import { createSlice } from '@reduxjs/toolkit';

export const diagramSlice = createSlice({
    name: 'diagram',
    initialState: {
      id: null,
      name: null,
      notation: null,
      type: null,
      elementtypes: [],
      connectiontypes: [],
    },
    reducers: {
      setDiagramData: (state, action) => {
        const { id, name, notation, type, elementtypes, connectiontypes } = action.payload;
        state.id = id;
        state.name = name;
        state.notation = notation;
        state.type = type;
        state.elementtypes = elementtypes; // Assuming elementtypes is an array
        state.connectiontypes = connectiontypes;
      },
    },
});

export const { setDiagramData } = diagramSlice.actions;

export const selectDiagramElements = (state) => state.diagram;

export default diagramSlice.reducer;
