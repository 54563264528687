// drawUtils.js
import store from '../Statemanagement/store'; // Adjust the path to your store configuration
import { selectElement, selectConnection, deselectAllElements, hoverElement } from '../Statemanagement/modelSlice';

export function handleCanvasClick(event, canvas, canvasOrigin, elements, connections, diagramId) {
  const rect = canvas.getBoundingClientRect();
  const x = event.clientX - rect.left - canvasOrigin.x;
  const y = event.clientY - rect.top - canvasOrigin.y;
  let selectedElementInfo = false;
  
  let elementClicked = false; // Flag to track if any element was clicked
  let connectionClicked = false; // Flag to track if any element was clicked

  elements.forEach(element => {
    if (isClickInsideElement(x, y, element)) {
      store.dispatch(selectElement({ diagramId, elementId: element.id }));
      elementClicked = true;
      selectedElementInfo = element;
    }
  });

  if(!elementClicked){  
    store.dispatch(deselectAllElements({ diagramId }));
    connections.forEach(connection => {
      const elementStart = elements.find(e => e.id === connection.elementstart);
      const connectorStart = elementStart ? elementStart.connectors.find(connector => connector.id === connection.connectorstart) : null;
      const elementEnd = elements.find(e => e.id === connection.elementend);
      const connectorEnd = elementEnd ? elementEnd.connectors.find(connector => connector.id === connection.connectorend) : null;
    
      if (isClickInsideConnection(connectorStart, connectorEnd, x, y )) {
        store.dispatch(selectConnection({ diagramId, connectionId: connection.id }));
        connectionClicked = true;
        selectedElementInfo = connection;
      }
    });
  }

  // If no element was clicked, deselect all elements in the diagram
  if (!elementClicked && !connectionClicked) {
    store.dispatch(deselectAllElements({ diagramId }));
  }

  return {elementClicked, connectionClicked, selectedElementInfo}; // Return true if an element was clicked, false otherwise
}

export function handleCanvasHover(event, canvas, canvasOrigin, elements, diagramId) {
  const rect = canvas.getBoundingClientRect();
  const x = event.clientX - rect.left - canvasOrigin.x;
  const y = event.clientY - rect.top - canvasOrigin.y;
  let hoveringElementInfo = false;

  let elementHover = false;
  
  elements.forEach(element => {
    if (isClickInsideElement(x, y, element)) {
      store.dispatch(hoverElement({ diagramId, elementId: element.id }));
      elementHover = true;
      hoveringElementInfo = element;
    }
  });

   // If no element was clicked, deselect all elements in the diagram
   if (!elementHover) {
    store.dispatch(deselectAllElements({ diagramId }));
  }

  return {elementHover, hoveringElementInfo}; // Return true if an element was clicked, false otherwise
}


// Function to draw the element based on its type
export function drawElement(ctx, element, origin, addConnectionMode) {
  var origin = origin || { x: 0, y: 0 };
  var type = element.geo.type;
  var width = element.geo.width || 0;
  var height = element.geo.height || 0;
  var color = element.geo.color || 'black';
  var border = element.geo.border || 'none';
  var state = element.state || 'default';
  var showName = element.showname || 'top'; //top, center, below, none
  var renderCase = element.case;
  const xpos = origin.x + (element.position?.x || 0);
  const ypos = origin.y + (element.position?.y || 0);

  // Improving the resolution by upscaling the elements and downscaling the canvas with the same aspect ratio
  const scale = 1;
  width *= scale; // Multiply width by scale
  height *= scale; // Multiply height by scale

  const selectColor = "#FDD41A";
  const selectBorderColor = "#FD8C1A"; // Red border color when selected
  const hoverBorderColor = "#164E4E"; // Red border color when hovered
  let lineWidth = '2';

  // Set fill style
  if (state === 'selected') {
    ctx.fillStyle = selectColor;
    border = 'full';
    ctx.strokeStyle = selectBorderColor; // Set stroke style to red when selected
    lineWidth = '2';
  } else if(state === 'hover') {
    border = 'full';
    ctx.strokeStyle = hoverBorderColor; // Set stroke style to red when hovered
    lineWidth = '2';
  }  else {
    ctx.fillStyle = color;
    ctx.strokeStyle = 'black'; // Default stroke style
  }

  // Draw the shape based on type
  switch (type) {
    case 'rectangle':
      drawRectangle(ctx, xpos, ypos, width, height, border, lineWidth);
      showName = handleRectangleRenderingCase(ctx, xpos, ypos, width, height, border, lineWidth, renderCase);
      break;
    case 'square':
      const side = Math.min(width, height);
      ctx.fillRect(xpos - side / 2, ypos - side / 2, side, side);
      drawBorder(ctx, xpos, ypos, side, side, border, lineWidth);
      break;
    case 'circle':
      const radius = Math.min(width, height) / 2;
      ctx.beginPath();
      ctx.arc(xpos, ypos, radius, 0, Math.PI * 2);
      ctx.fill();
      drawBorder(ctx, xpos, ypos, radius * 2, radius * 2, border, lineWidth);
      break;
    case 'ellipse':
      const radiusX = width / 2;
      const radiusY = height / 2;
      ctx.beginPath();
      ctx.ellipse(xpos, ypos, radiusX, radiusY, 0, 0, Math.PI * 2);
      ctx.fill();
      drawBorder(ctx, xpos, ypos, width, height, border, lineWidth);
      break;
    case 'triangle':
      ctx.beginPath();
      ctx.moveTo(xpos, ypos - height / 2); // Top vertex
      ctx.lineTo(xpos - width / 2, ypos + height / 2); // Bottom left vertex
      ctx.lineTo(xpos + width / 2, ypos + height / 2); // Bottom right vertex
      ctx.closePath();
      ctx.fill();
      drawBorder(ctx, xpos, ypos, width, height, border, lineWidth);
      break;
    case 'roundedRectangle':
      const cornerRadius = Math.min(width, height) / 5; // Adjust as needed
      ctx.beginPath();
      ctx.moveTo(xpos - width / 2 + cornerRadius, ypos - height / 2);
      ctx.lineTo(xpos + width / 2 - cornerRadius, ypos - height / 2);
      ctx.quadraticCurveTo(xpos + width / 2, ypos - height / 2, xpos + width / 2, ypos - height / 2 + cornerRadius);
      ctx.lineTo(xpos + width / 2, ypos + height / 2 - cornerRadius);
      ctx.quadraticCurveTo(xpos + width / 2, ypos + height / 2, xpos + width / 2 - cornerRadius, ypos + height / 2);
      ctx.lineTo(xpos - width / 2 + cornerRadius, ypos + height / 2);
      ctx.quadraticCurveTo(xpos - width / 2, ypos + height / 2, xpos - width / 2, ypos + height / 2 - cornerRadius);
      ctx.lineTo(xpos - width / 2, ypos - height / 2 + cornerRadius);
      ctx.quadraticCurveTo(xpos - width / 2, ypos - height / 2, xpos - width / 2 + cornerRadius, ypos - height / 2);
      ctx.closePath();
      ctx.fill();
      drawBorder(ctx, xpos, ypos, width, height, border, lineWidth);
      break;
    case 'pill':
      const pillRadius = height / 2;
      ctx.beginPath();
      ctx.moveTo(xpos - width / 2 + pillRadius, ypos - height / 2);
      ctx.lineTo(xpos + width / 2 - pillRadius, ypos - height / 2);
      ctx.arcTo(xpos + width / 2, ypos - height / 2, xpos + width / 2, ypos - height / 2 + pillRadius, pillRadius);
      ctx.lineTo(xpos + width / 2, ypos + height / 2 - pillRadius);
      ctx.arcTo(xpos + width / 2, ypos + height / 2, xpos + width / 2 - pillRadius, ypos + height / 2, pillRadius);
      ctx.lineTo(xpos - width / 2 + pillRadius, ypos + height / 2);
      ctx.arcTo(xpos - width / 2, ypos + height / 2, xpos - width / 2, ypos + height / 2 - pillRadius, pillRadius);
      ctx.lineTo(xpos - width / 2, ypos - height / 2 + pillRadius);
      ctx.arcTo(xpos - width / 2, ypos - height / 2, xpos - width / 2 + pillRadius, ypos - height / 2, pillRadius);
      ctx.closePath();
      ctx.fill();
      drawBorder(ctx, xpos, ypos, width, height, border, lineWidth);
      break;
    case 'diamond':
      ctx.beginPath();
      ctx.moveTo(xpos, ypos - height / 2); // Top vertex
      ctx.lineTo(xpos + width / 2, ypos);  // Right vertex
      ctx.lineTo(xpos, ypos + height / 2); // Bottom vertex
      ctx.lineTo(xpos - width / 2, ypos);  // Left vertex
      ctx.closePath();
      ctx.fill();
      drawBorder(ctx, xpos, ypos, width, height, border, lineWidth);
      break;    
    default:
      break;
    }

  // Draw the text
  if (element.name) {
    ctx.fillStyle = 'black'; // Set text color
    ctx.font = '12px Arial'; // Set text font and size
    ctx.textAlign = 'center'; // Center align the text horizontally

    if (showName === 'center') {
      ctx.textBaseline = 'middle'; // Vertically center the text
      ctx.fillText(element.name, xpos, ypos);
    } else if (showName === 'below') {
      ctx.textBaseline = 'top'; // Align text to the top
      ctx.fillText(element.name, xpos, ypos + height / 2 + 5); // Position text below the element
    } else if (showName === 'top') {
      ctx.textBaseline = 'top'; // Align text to the top
      ctx.fillText(element.name, xpos, ypos - height / 2 + 5); // Position text below the element
    }
  }


    // Draw connector dots if the element is selected or if addConnectionMode is true
  if ((state === 'selected' || (state === 'hover' && addConnectionMode)) && element.connectors) {
    element.connectors.forEach(connector => {
      drawConnector(ctx, origin, connector)
    });
  }
}

// Function to draw a rectangle with the specified parameters
const drawRectangle = (ctx, xpos, ypos, width, height, border, lineWidth) => {
  ctx.fillRect(xpos - width / 2, ypos - height / 2, width, height);
  drawBorder(ctx, xpos, ypos, width, height, border, lineWidth);
};

// Function to handle different rendering cases for the rectangle
const handleRectangleRenderingCase = (ctx, xpos, ypos, width, height, border, lineWidth, renderCase) => {
  let showName
  switch (renderCase) {
    case 'default':
      showName = 'top';
      break;
    case 'default-compartments':
      showName = 'top';
      drawRectangle(ctx, xpos, ypos, width, height, border, lineWidth);
      drawRectangle(ctx, xpos, ypos + height/2, width, height, border= `full`, lineWidth= 1);  // Additional block below
      break;
    case 'thin-lifeline':
      showName = 'center';
      ctx.setLineDash([5, 5]);
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(xpos, ypos + height / 2);
      ctx.lineTo(xpos, ypos + height * 6);
      ctx.stroke();
      ctx.setLineDash([]);  // Reset to solid line
      break;
    case 'thin-swimlane':
      showName = 'center';
      drawRectangle(ctx, xpos, ypos, width, height, border, lineWidth);
      ctx.beginPath();
      ctx.rect(xpos - width / 2, ypos + height / 2, width, height * 6);  // Block without fill but with border below
      ctx.lineWidth = 1;
      ctx.stroke();
      break;
    case 'symbol':
      showName = 'center';
      break;
    case 'context':
      showName = 'top';
      break;
    default:
  }
  return showName
};


function drawConnector(ctx, origin, connector){
  const dotRadius = 4; // Default radius of the connector dots
  const hoverDotRadius = 7; // Radius of the connector dots when hovered
  const dotFillColor = '#ffffff'; // Light blue fill color
  const dotBorderColor = '#9393C6'; // Darker blue border color
  const dotBorderWidth = 2; // Border width of the connector dots

  const { x, y, state } = connector;
  const radius = state === 'hover' ? hoverDotRadius : dotRadius;

  ctx.beginPath();
  ctx.arc(x + origin.x, y + origin.y, radius, 0, Math.PI * 2);
  ctx.fillStyle = dotFillColor;
  ctx.fill();
  ctx.lineWidth = dotBorderWidth;
  ctx.strokeStyle = dotBorderColor;
  ctx.stroke();
}

// Function to draw border based on border type
function drawBorder(ctx, xpos, ypos, elementWidth, elementHeight, border, lineWidth) {
  // Set line thickness
  ctx.lineWidth = lineWidth;

  switch (border) {
    case 'dashed':
      ctx.setLineDash([5, 5]);
      ctx.strokeRect(xpos - elementWidth / 2, ypos - elementHeight / 2, elementWidth, elementHeight);
      break;
    case 'dotted':
      ctx.setLineDash([2, 2]);
      ctx.strokeRect(xpos - elementWidth / 2, ypos - elementHeight / 2, elementWidth, elementHeight);
      break;
    case 'full':
      ctx.strokeRect(xpos - elementWidth / 2, ypos - elementHeight / 2, elementWidth, elementHeight);
      break;
    case 'none':
    default:
      break;
  }

  // Reset line dash to default
  ctx.setLineDash([]);
}

// Helper function to check if a click is inside an element
function isClickInsideElement(x, y, element) {
  const width = element.geo.width + 30; // adding boundary for smoother selection
  const height = element.geo.height + 30; // adding boundary for smoother selection
  const xpos = element.position.x;
  const ypos = element.position.y;

  switch (element.geo.type) {
    case 'rectangle':
    case 'square':
      return x >= xpos - width / 2 && x <= xpos + width / 2 && y >= ypos - height / 2 && y <= ypos + height / 2;
    case 'circle':
      const radius = Math.min(width, height) / 2;
      return Math.hypot(x - xpos, y - ypos) <= radius;
    case 'ellipse':
      const radiusX = width / 2;
      const radiusY = height / 2;
      return ((x - xpos) ** 2) / (radiusX ** 2) + ((y - ypos) ** 2) / (radiusY ** 2) <= 1;
    case 'triangle':
      // Triangle hit detection (using barycentric coordinates)
      const v0 = { x: 0, y: -height / 2 };
      const v1 = { x: -width / 2, y: height / 2 };
      const v2 = { x: width / 2, y: height / 2 };
      const a = ((v1.y - v2.y) * (x - (xpos + width / 2)) + (v2.x - v1.x) * (y - (ypos + height / 2))) / ((v1.y - v2.y) * (v0.x - (xpos + width / 2)) + (v2.x - v1.x) * (v0.y - (ypos + height / 2)));
      const b = ((v2.y - v0.y) * (x - (xpos + width / 2)) + (v0.x - v2.x) * (y - (ypos + height / 2))) / ((v2.y - v0.y) * (v1.x - (xpos + width / 2)) + (v0.x - v2.x) * (v1.y - (ypos + height / 2)));
      const c = 1 - a - b;
      return a >= 0 && b >= 0 && c >= 0;
    case 'roundedRectangle':
      const cornerRadius = Math.min(width, height) / 5; // Adjust as needed
      const innerWidth = width - 2 * cornerRadius;
      const innerHeight = height - 2 * cornerRadius;
      if (x >= xpos - innerWidth / 2 && x <= xpos + innerWidth / 2 && y >= ypos - innerHeight / 2 && y <= ypos + innerHeight / 2) {
        return true;
      }
      if ((x - xpos) ** 2 + (y - ypos + height / 2 - cornerRadius) ** 2 <= cornerRadius ** 2) {
        return true;
      }
      if ((x - xpos) ** 2 + (y - ypos - height / 2 + cornerRadius) ** 2 <= cornerRadius ** 2) {
        return true;
      }
      if ((x - xpos + width / 2 - cornerRadius) ** 2 + (y - ypos) ** 2 <= cornerRadius ** 2) {
        return true;
      }
      if ((x - xpos - width / 2 + cornerRadius) ** 2 + (y - ypos) ** 2 <= cornerRadius ** 2) {
        return true;
      }
      return false;
    case 'pill':
      const pillRadius = height / 2;
      const pillWidth = width - height; // Width of the central rectangle part
      if (x >= xpos - pillWidth / 2 && x <= xpos + pillWidth / 2 && y >= ypos - pillRadius && y <= ypos + pillRadius) {
        return true;
      }
      if ((x - (xpos - pillWidth / 2)) ** 2 + (y - ypos) ** 2 <= pillRadius ** 2) {
        return true;
      }
      if ((x - (xpos + pillWidth / 2)) ** 2 + (y - ypos) ** 2 <= pillRadius ** 2) {
        return true;
      }
      return false;
    case 'diamond':
      // Diamond hit detection
      const dx = Math.abs(x - xpos);
      const dy = Math.abs(y - ypos);
      return (dx / (width / 2) + dy / (height / 2)) <= 1;
    default:
      return false;
  }
}

const isClickInsideConnection = (connectorstart, connectorend, x, y) => {
  const distance = pointLineDistance(connectorstart, connectorend, { x, y });
  if (distance < 5) {
    return isWithinLineSegment(connectorstart, connectorend, { x, y });
  }
  return false;
};

const pointLineDistance = (start, end, point) => {
  // Using the formula for distance from point to line
  const { x: x1, y: y1 } = start;
  const { x: x2, y: y2 } = end;
  const { x, y } = point;
  const numerator = Math.abs((y2 - y1) * x - (x2 - x1) * y + x2 * y1 - y2 * x1);
  const denominator = Math.sqrt((y2 - y1) ** 2 + (x2 - x1) ** 2);
  return numerator / denominator;
};

const isWithinLineSegment = (start, end, point) => {
  const { x: x1, y: y1 } = start;
  const { x: x2, y: y2 } = end;
  const { x, y } = point;

  const dotProduct = (x - x1) * (x2 - x1) + (y - y1) * (y2 - y1);
  if (dotProduct < 0) return false;

  const squaredLength = (x2 - x1) ** 2 + (y2 - y1) ** 2;
  if (dotProduct > squaredLength) return false;

  return true;
};

  // Function to draw the dotted grid
function drawGrid(canvas, delx, dely, ctx, windowOrigin, canvasOrigin) {
    const gridSize = 25; // Size of each grid cell
    
    const gridconstx = Math.floor(canvas.width/gridSize)
    const gridconsty = Math.floor(canvas.height/gridSize)
    //panning the grid by simulating
    let delpanx = windowOrigin.x - canvasOrigin.x;
    let delpany = windowOrigin.y - canvasOrigin.y;
    let gridpanx = delpanx - (Math.floor(delpanx/gridSize)*gridSize)
    let gridpany = delpany - (Math.floor(delpany/gridSize)*gridSize)
    if (gridconstx % 2 === 0) {
      delx = ((canvas.width % gridSize) / 2)-gridpanx;
    } else {
      delx = (((canvas.width % gridSize) / 2) + gridSize/2) -gridpanx;
    }
    if (gridconsty % 2 === 0) {
      dely = ((canvas.height % gridSize) / 2)-gridpany;
    } else {
      dely = (((canvas.height % gridSize) / 2) + gridSize/2) -gridpany;
    }
    for (let x = (delx); x <= (window.innerWidth); x += gridSize) {
      for (let y = (dely); y <= (window.innerHeight); y += gridSize) {
        drawDot(x, y, ctx); // Call a helper function to draw a dot at each corner
      }
    }
  };
    
  // Function to draw a dot at a specific position
function drawDot(x, y, ctx) {
    const dotSize = 0.6; // Size of the dot
    ctx.beginPath();
    ctx.arc(x, y, dotSize, 0, Math.PI * 2);
    ctx.fillStyle = '#999999'; // Color of the dots
    ctx.fill();
  };

  export function drawDiagram(canvas, delx, dely, ctx, windowOrigin, canvasOrigin, diagramElements = [], connectionElements = [], addConnectionMode) {
    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawGrid(canvas, delx, dely, ctx, windowOrigin, canvasOrigin);
  
      // Ensure diagramElements is an array
      if (Array.isArray(diagramElements)) {
        // Execute drawElements function with position and geometry data of each element
        diagramElements.forEach(element => {
          drawElement(ctx, element, canvasOrigin, addConnectionMode);
        });
      } else {
        console.error('diagramElements is not an array or is undefined');
      }
  
      // Ensure connectionElements is an array
      if (Array.isArray(connectionElements)) {
        // Execute connectElements function with info of the connector fetching each relevant element connector
        connectionElements.forEach(connection => {
          const elementStart = diagramElements.find(e => e.id === connection.elementstart);
          const connectorStart = elementStart ? elementStart.connectors.find(connector => connector.id === connection.connectorstart) : null;
          const elementEnd = diagramElements.find(e => e.id === connection.elementend);
          const connectorEnd = elementEnd ? elementEnd.connectors.find(connector => connector.id === connection.connectorend) : null;
          const startdir = connection.connectorstart;
          const enddir = connection.connectorend;
          // Use geo info or default values
          const lineStyle = (connection.geo && connection.geo.lineStyle) || "full";
          const startStyle = (connection.geo && connection.geo.startStyle) || "arrow-full";
          const endStyle = (connection.geo && connection.geo.endStyle) || "arrow-full";
          const connectorState = (connection.state) || "default";
  
          if (elementStart && elementEnd && connectorStart && connectorEnd) {
            connectElements(ctx, connectorStart, connectorEnd, startdir, enddir, connection.lineType, canvasOrigin, lineStyle, startStyle, endStyle, connectorState);
          } else {
            console.error('Connection could not be drawn:', {
              elementStart,
              connectorStart,
              elementEnd,
              connectorEnd,
              connection
            });
          }
        });
      } else {
        console.error('connectionElements is not an array or is undefined');
      }
    }
  }
  


function drawStraightLine(ctx, start, end, lineStyle = 'full', startStyle = 'none', endStyle = 'none', state) {
  ctx.beginPath();
  ctx.moveTo(start.x, start.y);
  ctx.lineTo(end.x, end.y);
  if (state === 'selected'){
    ctx.strokeStyle = "#FD8C1A"; // Use strokeStyle for lines
  }else{
    ctx.strokeStyle = "#333";
  }
  // Set line style
  if (lineStyle === 'dashed') {
      ctx.setLineDash([5, 5]); // Dashed line
  } else if (lineStyle === 'dotted') {
      ctx.setLineDash([2, 4]); // Dotted line
  } else {
      ctx.setLineDash([]); // Full line
  }
  
  // Set line width
  ctx.lineWidth = 2; // Default thickness
  
  // Draw line
  ctx.stroke();
  const angle = calculateOrientation(start, end);
  // Draw start and end styles
  drawLineStyles(ctx, start, startStyle, angle - Math.PI);
  drawLineStyles(ctx, end, endStyle, angle );
}

function drawCorneredLine(ctx, start, end, startDir, endDir, lineStyle = 'full', startStyle = 'none', endStyle = 'none', state) {
  let radius = 0;
  const radiusMax = 5; 

  const straightdelta = radiusMax*2; // set the delta in which a cornered line will switch to a straight line.
  const dx = Math.abs(start.x-end.x);
  const dy = Math.abs(start.y-end.y);

  if(dx <= straightdelta){
    radius = 0.5*dx;
  }else if(dy <= straightdelta){
    radius = 0.5*dy;
  }else{
   radius = radiusMax; // Adjust the radius as needed for rounding
  }

  if (state === 'selected'){
    ctx.strokeStyle = "#FD8C1A"; // Use strokeStyle for lines
  }else{
    ctx.strokeStyle = "#333";
  }
  
  ctx.beginPath();
  ctx.moveTo(start.x, start.y);

  let midX = (start.x + end.x) / 2;
  let midY = (start.y + end.y) / 2;

  // Horizontal movement from start point
  if (startDir === 'right') {
      ctx.lineTo(midX - radius, start.y); // Horizontal line to the corner start
      if(start.y <= end.y){
        ctx.arcTo(midX, start.y, midX, start.y + radius, radius); // Rounded corner
      }else{
        ctx.arcTo(midX, start.y, midX, start.y - radius, radius); // Rounded corner
      }
  } else if (startDir === 'left') {
      ctx.lineTo(midX + radius, start.y); // Horizontal line to the corner start
      if(start.y <= end.y){
        ctx.arcTo(midX, start.y, midX, start.y + radius, radius); // Rounded corner
      }else{
        ctx.arcTo(midX, start.y, midX, start.y - radius, radius); // Rounded corner
      }
  } else if (startDir === 'bottom') {
      ctx.lineTo(start.x, midY - radius); // Vertical line to the corner start
      if(start.x <= end.x){
        ctx.arcTo(start.x, midY, start.x + radius, midY, radius); // Rounded corner
      }else{
        ctx.arcTo(start.x, midY, start.x - radius, midY, radius); // Rounded corner
      }
    } else if (startDir === 'top') {
      ctx.lineTo(start.x, midY + radius); // Vertical line to the corner start
      if(start.x <= end.x){
        ctx.arcTo(start.x, midY, start.x + radius, midY, radius); // Rounded corner
      }else{
        ctx.arcTo(start.x, midY, start.x - radius, midY, radius); // Rounded corner
      }
    }

  // Horizontal movement to the end point
  if (endDir === 'right') {
      if(start.y <= end.y){
      ctx.lineTo(midX, end.y - radius); // Vertical line to the end corner start
      ctx.arcTo(midX, end.y, midX - radius, end.y, radius); // Rounded corner
      }else{
      ctx.lineTo(midX, end.y + radius); // Vertical line to the end corner start
      ctx.arcTo(midX, end.y, midX - radius, end.y, radius); // <----Rounded corner
      }
      ctx.lineTo(end.x, end.y); // Horizontal line to the end point
  } else if (endDir === 'left') {
      if(start.y <= end.y){
        ctx.lineTo(midX, end.y - radius); // Vertical line to the end corner start
        ctx.arcTo(midX, end.y, midX + radius, end.y, radius); // <---- Rounded corner
      }else{
        ctx.lineTo(midX, end.y + radius); // Vertical line to the end corner start
        ctx.arcTo(midX, end.y, midX + radius, end.y, radius); // Rounded corner
      }
      ctx.lineTo(end.x, end.y); // Horizontal line to the end point
  } else if (endDir === 'bottom') {
      if(start.x <= end.x){
        ctx.lineTo(end.x - radius, midY); // Horizontal line to the end corner start
        ctx.arcTo(end.x, midY, end.x, midY - radius, radius); // Rounded corner
      }else{
        ctx.lineTo(end.x + radius, midY); // Horizontal line to the end corner start
        ctx.arcTo(end.x, midY, end.x, midY - radius, radius); // Rounded corner
      }
      ctx.lineTo(end.x, end.y); // Vertical line to the end point
  } else if (endDir === 'top') {
    if(start.x <= end.x){
      ctx.lineTo(end.x - radius, midY); // Horizontal line to the end corner start
      ctx.arcTo(end.x, midY, end.x, midY + radius, radius); // Rounded corner
    }else{
      ctx.lineTo(end.x + radius, midY); // Horizontal line to the end corner start
      ctx.arcTo(end.x, midY, end.x, midY + radius, radius); // Rounded corner
    }
    ctx.lineTo(end.x, end.y); // Vertical line to the end point
  }
  // Set line style
  if (lineStyle === 'dashed') {
      ctx.setLineDash([5, 5]); // Dashed line
  } else if (lineStyle === 'dotted') {
      ctx.setLineDash([2, 4]); // Dotted line
  } else {
      ctx.setLineDash([]); // Full line
  }
  
  // Set line width
  ctx.lineWidth = 2; // Default thickness
  
  // Draw line
  ctx.stroke();
  
  let angle = 0;
  if(startDir === "top" || endDir === "bottom" ){
    angle = -0.5*Math.PI;
  }else if(startDir === "bottom" || endDir === "top" ){
    angle = 0.5*Math.PI;
  }else if(startDir === "right" || endDir === "left" ){
    angle = 0;
  }else if(startDir === "left" || endDir === "right" ){
    angle = Math.PI;
  }


  // Draw start and end styles
  drawLineStyles(ctx, start, startStyle, angle - Math.PI);
  drawLineStyles(ctx, end, endStyle, angle );
}

function drawLineStyles(ctx, pos, style, angle) {
  // Set line style properties
  ctx.fillStyle = "#000"; // Black color
  
  // Draw line styles based on the given style
  switch (style) {
      case 'arrow-full':
        drawArrowhead(ctx, pos, angle, 'full', 15);
        break;
      case 'arrow-empty':
        drawArrowhead(ctx, pos, angle, 'empty', 15);
        break;
      case 'arrow-light':
        drawArrowhead(ctx, pos, angle, 'light', 15);
        break;
      case 'diamond-full':
        drawDiamond(ctx, pos, angle, 'full');   // Full diamond
        break;
      case 'diamond-empty':
        drawDiamond(ctx, pos, angle, 'empty');   // Empty diamond
        break;
      default:
          // No line style
          break;
  }
}

function drawArrowhead(ctx, position, angle, style, size = 10) {
  ctx.save();
  ctx.translate(position.x, position.y);
  ctx.rotate(angle);

  ctx.beginPath();

  // Define arrowhead shape based on the style
  switch (style) {
      case 'full':
          ctx.moveTo(0, 0);
          ctx.lineTo(-size, size * 0.5);
          ctx.lineTo(-size, -size * 0.5);
          ctx.fillStyle = "#000"; // Black color
          ctx.closePath();
          ctx.fill();
          break;
      case 'empty':
          size *= 0.6;
          ctx.moveTo(0, 0);
          ctx.lineTo(-size, size * 0.5);
          ctx.lineTo(-size, -size * 0.5);
          ctx.fillStyle = "#fff"; // White color for fill
          ctx.fill();
      
          ctx.beginPath();
          ctx.moveTo(0, 0);
          ctx.lineTo(-size, size * 0.5);
          ctx.lineTo(-size, -size * 0.5);
          ctx.closePath();
          ctx.strokeStyle = "#000"; // Black color for border
          ctx.lineWidth = size * 0.4;
          ctx.setLineDash([]);
          ctx.stroke();
          ctx.closePath();
          ctx.fill();
          break;
      case 'light':
          size *= 0.8;
          ctx.beginPath();
          ctx.moveTo(-size *0.6, size * 0.4);
          ctx.lineTo(0, 0);
          ctx.lineTo(-size *0.6, -size * 0.4);
          ctx.strokeStyle = "#000"; // Black color for border
          ctx.lineWidth = size * 0.15;
          ctx.setLineDash([]);
          ctx.stroke();
          ctx.closePath();
          break;
      default:
          break;
  }


  ctx.restore();
}



function drawDiamond(ctx, position, angle, style, size = 18) {
  ctx.save();
  ctx.translate(position.x, position.y);
  ctx.rotate(angle);

  ctx.beginPath();

  // Define diamond shape based on the style
  switch (style) {
      case 'full':
          ctx.moveTo(-size * 0.5, -size * 0.35);
          ctx.lineTo(-size, 0);
          ctx.lineTo(-size * 0.5, size * 0.35);
          ctx.lineTo(0, 0);
          break;
      case 'empty':
          size = size*0.8;
          ctx.moveTo(-size * 0.5, -size * 0.35);
          ctx.lineTo(-size, 0);
          ctx.lineTo(-size * 0.5, size * 0.35);
          ctx.lineTo(0, 0);
          ctx.fillStyle = "#fff"; // White color for fill
          ctx.fill();

          ctx.beginPath();
          ctx.moveTo(-size * 0.5, -size * 0.35);
          ctx.lineTo(-size, 0);
          ctx.lineTo(-size * 0.5, size * 0.35);
          ctx.lineTo(0, 0);
          ctx.strokeStyle = "#000"; // Black color for border
          ctx.lineWidth = size * 0.15;
          ctx.setLineDash([]);
          ctx.stroke();
          ctx.closePath();
          ctx.fill();

          break;
      default:
          break;
  }

  ctx.closePath();

  // Set fill style
  if (style === 'full') {
      ctx.fillStyle = "#000"; // Black color
      ctx.fill();
  } else if (style === 'empty') {
      ctx.strokeStyle = "#000"; // Black color
      ctx.stroke();
  }

  ctx.restore();
}



function calculateOrientation(start, end){
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const angle = Math.atan2(dy, dx);
return angle;
}


function connectElements(ctx, start, end, startdir, enddir, lineType, origin, lineStyle, startStyle, endStyle, state) {
  const startxpos = origin.x + (start.x || 0);
  const startypos = origin.y + (start.y || 0);
  const endxpos = origin.x + (end.x || 0);
  const endypos = origin.y + (end.y || 0);
  
  // Define start and end positions as objects
  const startpos = { x: startxpos, y: startypos };
  const endpos = { x: endxpos, y: endypos };

  const straightdelta = 0.5; // set the delta in which a cornered line will switch to a straight line.
  const dx = Math.abs(start.x-end.x);
  const dy = Math.abs(start.y-end.y);

  if(dx <= straightdelta || dy <= straightdelta){
    lineType = 'straight';
  }

  if (lineType === 'straight') {
      drawStraightLine(ctx, startpos, endpos, lineStyle, startStyle, endStyle, state);
  } else if (lineType === 'cornered') {
      drawCorneredLine(ctx, startpos, endpos, startdir, enddir, lineStyle, startStyle, endStyle, state);
  }

  if(state === 'selected'){
    drawConnector(ctx, origin, {x:start.x, y:start.y, state: 'default'});
    drawConnector(ctx, origin, {x:end.x, y:end.y, state: 'default'});
  }
}

    
    
    
    