import React, { useState , useEffect, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { addDiagramElement, deleteDiagramElement, getRelatedPosition, setNewOwnerProperty } from './modelUtils'
import DiagramElementToolbar from './DiagramElementToolbar';
import HomeLink from '../Menu/HomeLink';
import DiagramViewer from './DiagramViewer';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import BackToLink from '../Menu/BackToLink';
import SafeButtonHomeExtesion from '../Menu/SafeButtonHomeExtension';

function generateUniqueId() {
  return uuidv4();
}

function DiagramEditor() {
  const [selectedShape, setSelectedShape] = useState(null);
  const [selectedCase, setSelectedCase] = useState(null);
  const [infoText, setInfoText] = useState('IDLE');
  const [addElementMode, setAddElementMode] = useState(false);
  const [addConnectionMode, setAddConnectionMode] = useState(false);
  const [selectedTypeButton, setSelectedTypeButton] = useState(null);
  const [addElement, setAddElement] = useState(false);
  const [newElementName, setNewElementName] = useState('');
  const [newElementPosition, setNewElementPosition] = useState({x: 0, y: 0});
  const inputRef = useRef(null); // Ref to the input field
  const { id } = useParams();

  const location = useLocation();
  const { diagramId, diagramName, diagramNotation, diagramType, modelId } = location.state || {};

  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedOwner, setSelectedOwner] = useState('none');

  const diagramState = useSelector(state => state.diagram); // Access the diagram state from Redux

  const handleSelectedElementChange = (selectedElementInfo) => {
      setSelectedElement(selectedElementInfo);
  };

  useEffect(() => {
    if(addConnectionMode === false){
      setSelectedTypeButton(null);
    }
  }, [addConnectionMode])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        // Handle Escape key press
        if (addElement) {
          // If adding element, cancel adding element
          setAddElement(false);
          setNewElementName('');
          handleAddElement('cancel');
          setSelectedTypeButton(null);
        }
        if (addConnectionMode) {
          // If adding connection, cancel adding connection
          setAddConnectionMode(false);
          setSelectedTypeButton(null);
          setInfoText("action canceled");
        }
      }
    };
  
    document.addEventListener('keydown', handleKeyDown);
  
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [addElement, addConnectionMode]); // Dependencies: addElement, addConnectionMode
  

  useEffect(() => {
    // Focus the input field when addElement is true
    if (addElement) {
      inputRef.current.focus();
    }
  }, [addElement]);

  useEffect(() => {
    const handleKeyDown = (event) => {
        if (event.key === 'Delete') {
            // Ensure there is a selected element
            if (selectedElement) {
                // Call handleDelete with the selected element
                handleDelete(selectedElement);
            }
        }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
        document.removeEventListener('keydown', handleKeyDown);
    };
}, [selectedElement]); // Include selectedElement in the dependency array

  const handleDelete = (selectedElement) => {
      // Your delete logic here
      const selectedElementId = selectedElement.id;
      deleteDiagramElement(diagramId, selectedElementId)
  };


  const updateInfoText = (text) => {
    setInfoText(text);
  };

  const handleAddElement = (action) => {
    setAddElement(false);
    setAddElementMode(false);

    if(action === 'add'){

      let elementId = generateUniqueId();
      let elementType = selectedShape;
      let elementCase = selectedCase;
      let elementName = newElementName;

      let elementPosition = 0;
      let elementOwner = 'none'

      if(selectedOwner != 'none'){
        elementOwner = selectedOwner.id; // default is none
        const validProperty = setNewOwnerProperty(diagramId, elementOwner, elementId, elementType);
        if (!validProperty) {
          setInfoText(`${selectedOwner.name} cannot own ${elementType}`);
          setSelectedTypeButton(false);
          return;
        }
        elementPosition = getRelatedPosition(selectedOwner.position, selectedOwner.geo, newElementPosition);
      }else{
        elementPosition = newElementPosition;
        elementOwner = selectedOwner; // default is none
      }

    // add an element to the diagram
      const newDiagramElement = { id: elementId, type: elementType, name: elementName, case: elementCase, x: elementPosition.x, y: elementPosition.y };
      addDiagramElement(diagramId, newDiagramElement, diagramNotation, diagramState, elementOwner);
      setSelectedTypeButton(false);
      setSelectedOwner('none');
    }else{
      setInfoText("action canceled");
    }
    setSelectedTypeButton(false);
    setNewElementName('')
  };

  const handleElementNameChange = (event) => {
    setNewElementName(event.target.value);
  };

  const handleKeyPress = (event) => {
    // Trigger 'Add' button on Enter key press
    if (event.key === 'Enter') {
      handleAddElement('add');
    }
  };

  return (
    <div id="diagram-editor" className="diagram-view-container">
      <div id="infoText" className="infoTextBar">{infoText}</div>
      <div>
        {addElement && (
          <div id="addElementField" className='addElementField'>
            <label>
              Element Name:
              <input
                ref={inputRef}
                type="text"
                value={newElementName}
                onChange={handleElementNameChange}
                onKeyPress={handleKeyPress}
              />
            </label>
            <button className={`field-button`} onClick={() => handleAddElement('add')}>Add</button>
            <button className={`field-button`} onClick={() => handleAddElement('cancel')}>Cancel</button>
          </div>
        )}
        <DiagramViewer
          selectedShape={selectedShape}
          addElementMode={addElementMode}
          setAddElementMode={setAddElementMode}
          updateInfoText={updateInfoText}
          setSelectedTypeButton={setSelectedTypeButton}
          addElement={addElement}
          setAddElement={setAddElement}
          setSelectedOwner={setSelectedOwner}
          newElementPosition={newElementPosition}
          setNewElementPosition={setNewElementPosition}
          onSelect={handleSelectedElementChange}
          addConnectionMode ={addConnectionMode}
          setAddConnectionMode ={setAddConnectionMode}
        />
        <DiagramElementToolbar
          setAddElementMode={setAddElementMode}
          setAddConnectionMode={setAddConnectionMode}
          setSelectedShape={setSelectedShape}
          setSelectedCase={setSelectedCase}
          updateInfoText={updateInfoText}
          setSelectedTypeButton={setSelectedTypeButton}
          selectedTypeButton={selectedTypeButton}
        />
        <HomeLink           
          path={`/model/${modelId}`}
          modelId={modelId}
          diagramId={diagramId} />
        <SafeButtonHomeExtesion
          modelId={modelId}
          diagramId={diagramId} />
      </div>
    </div>
  );
  
};

export default (DiagramEditor);
