import React from 'react';

import '../Menu/HomeLink.css';

function DiagramNameTag({ name, type }) {


    return (
        <div className="home-box-extention-right">
            <button 
                id="backBtn" 
                className="digram-name-button">
                    <p className="diagram-title">{type} | {name}</p>
            </button>
        </div>
    );
}

export default DiagramNameTag;
