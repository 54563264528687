import React, { useState, useEffect } from 'react';
import './LandingPage.css'
import axios from '../../../axiosConfig';

import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { Button } from "../../ui/button"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog"



function LoginButton() {

  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Hook to access the navigate function

  const fetchUserData = async (action) => {
      try {        
        const response = await axios.get('/api/user', { withCredentials: true });
    
        setUser(response.data.user);
      } catch (err) {
        if (err.response.status === 401) {
          // Handle unauthorized access (e.g., redirect to login page)
          console.log('Unauthorized access. Redirecting to login page...');
          // Implement redirection or display a message to the user
        } else {
          // Handle other types of errors
          console.error('Error fetching model:', err);
        }
      }
  };

  
  const handleLogoutButtonClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/logout', { withCredentials: true });
      if (response.status === 200) {
        navigate(`/`);
      } else {
        console.error('Logout failed with status:', response.status);
      }
    } catch (err) {
      console.error('Error logging out:', err);
    }
  };

  return (
      <Dialog>
        <DialogTrigger asChild>
          <Button 
            type="submit"
            variant='default' 
            size='icon'
            onClick={fetchUserData}
          >
            <FontAwesomeIcon icon={faUser} size="1x"/>
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-xs" width='250px'>
          <DialogHeader>
            <DialogTitle>Hi, {user}!</DialogTitle>
          </DialogHeader>
          <DialogFooter className="sm:justify-start">
            <DialogClose asChild>
              <Button 
                type="submit"
                onClick={handleLogoutButtonClick}>
                Logout
              </Button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
  );
}

export default LoginButton;