// ModelSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    loaded: false,
    models: [],
    frameworks: [],
    notations: [],
  },
  reducers: {
    setMenuData: (state, action) => {
      state.models = action.payload.models;
      state.frameworks = action.payload.frameworks;
      state.notations = action.payload.notations;
    },
    loadMenuData: (state, action) => {
      state.loaded = action.payload.loaded
    }
  },
});

export const { setMenuData: setMenuData, loadMenuData: loadMenuData} = menuSlice.actions;

export const selectMenuData = (state) => state.menu;

export default menuSlice.reducer;
