import React from 'react';
import { useSelector } from 'react-redux';
import { selectDiagramElements } from '../Statemanagement/diagramSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, faLightbulb, faCircle, faSquare, faEllipsis, faRectangleXmark, 
  faCaretUp, faCircleNotch, faPlug, faDiamond, faMinus, faClone, faPills, 
  faPersonWalking, faArrowTrendUp, faPlay, faMessage 
} from '@fortawesome/free-solid-svg-icons';
import './toolbar.css';

function DiagramElementToolbar({ setAddElementMode, setAddConnectionMode, setSelectedShape, setSelectedCase, updateInfoText, setSelectedTypeButton, selectedTypeButton }) {
  const diagramData = useSelector(selectDiagramElements);
  const { elementtypes, connectiontypes } = diagramData;

  const handleElementButtonClick = (type, caseName) => {
    setSelectedTypeButton(`${type}-${caseName}`); // Set the active button on click
    setSelectedShape(type); // Update the selected type in the parent component
    setSelectedCase(caseName); // Update the selected type in the parent component
    setAddElementMode(true); // Enable Add Element mode
    setAddConnectionMode(false); // Disable Add Connection mode
    updateInfoText(`Add element of type: ${type} and case: ${caseName}`);
  };

  const handleConnectionButtonClick = (type) => {
    setSelectedTypeButton(type); // Set the active button on click
    setSelectedShape(type); // Update the selected type in the parent component
    setAddElementMode(false); // Disable Add Element mode
    setAddConnectionMode(true); // Enable Add Connection mode
    updateInfoText(`Add connection of type: ${type}`);
  };

  const isButtonSelected = (type) => {
    return type === selectedTypeButton ? 'selected' : '';
  };

  const getIconForElementType = (type) => {
    switch (type) {
      case 'circ-el':
        return faCircle;
      case 'sqr-el':
        return faSquare;
      case 'rect-el':
        return faRectangleXmark;
      case 'elip-el':
        return faCircleNotch;
      case 'tria-el':
        return faCaretUp;
      case 'block-el':
        return faClone;
      case 'actor-el':
        return faUser;
      case 'acti-el':
        return faPersonWalking;
      case 'useca-el':
        return faLightbulb;
      case 'stat-el':
        return faPills;
      case 'fork-el':
        return faMinus;
      case 'deci-el':
        return faDiamond;
      case 'port-el':
        return faPlug;
      case 'init-el':
        return faCircle;
      default:
        return faEllipsis; // Default icon if no match is found
    }
  };

  const getIconForConnectionType = (type) => {
    switch (type) {
      case 'asso':
        return faMinus;
      case 'conct':
        return faMinus;
      case 'contr':
        return faArrowTrendUp;
      case 'mess':
        return faMessage;
      case 'trans':
        return faPlay;
      case 'decomp':
        return faDiamond;
      case 'genr':
        return faCaretUp;
      default:
        return faEllipsis; // Default icon if no match is found
    }
  };

  return (
    <div className="element-toolbar">
      {elementtypes.map((elementType, index) => (
        elementType.geo.map((geo, caseIndex) => (
          <button
            key={`${index}-${caseIndex}`}
            id={`${elementType.type}-${geo.case}Btn`}
            className={`toolbar-button ${isButtonSelected(`${elementType.type}-${geo.case}`)}`}
            onMouseDown={() => handleElementButtonClick(elementType.type, geo.case)}
          >
            <FontAwesomeIcon icon={getIconForElementType(elementType.type)} size="2x" />
          </button>
        ))
      ))}
      <hr className="separator-line" />
      {connectiontypes.map((connectionType, index) => (
        <button
          key={index}
          id={`${connectionType.type}Btn`}
          className={`toolbar-button ${isButtonSelected(connectionType.type)}`}
          onMouseDown={() => handleConnectionButtonClick(connectionType.type, true)}
        >
          <FontAwesomeIcon icon={getIconForConnectionType(connectionType.type)} size="2x" />
        </button>
      ))}
    </div>
  );
}

export default DiagramElementToolbar;
