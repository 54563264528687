import React, { useState, useEffect } from 'react';
import TitleBox from './TitleBox';
import MainContainer from './LibraryContainer/MainContainer';
import { useDispatch, useSelector } from 'react-redux';
import { setMenuData, loadMenuData } from '../Statemanagement/menuSlice';
import axios from '../../axiosConfig';

axios.get('/api/test')
  .then(response => {
    console.log('Backend response:', response.data);
  })
  .catch(error => {
    console.error('Error communicating with backend:', error);
  });

function Home() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const menuloaded = useSelector(state => state.menu.loaded);

  const fetchNotations = async () => {
    try {
      const response = await axios.get('/api/notations');
      return { notations: response.data };
    } catch (error) {
      console.error('Error fetching notations:', error);
      throw error;
    }
  };

  const fetchFrameworks = async () => {
    try {
      const response = await axios.get('/api/frameworks');
      return { frameworks: response.data };
    } catch (error) {
      console.error('Error fetching notations:', error);
      throw error;
    }
  };

  const fetchModels = async () => {
    try {
      const response = await axios.get('/api/models');
      return { models: response.data };
    } catch (error) {
      console.error('Error fetching notations:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const notationsData = await fetchNotations();
        const frameworksData = await fetchFrameworks();
        const modelsData = await fetchModels();

        const combinedData = {
          ...notationsData,
          ...frameworksData,
          ...modelsData
        };
        dispatch(setMenuData(combinedData));
        dispatch(loadMenuData({ loaded: true }));
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    if (!menuloaded) {
      console.log("fetching");
      fetchMenuData();
    }
  }, [dispatch, menuloaded]);

  return (
    <div className="home-container">
      <TitleBox />
      <MainContainer title='Models -Usage' type="models" />
      <MainContainer title='Frameworks -Domain Specific Language' type="frameworks" />
      <MainContainer title='Notations -Spoken Language' type="notations" />
    </div>
  );
}

export default Home;
