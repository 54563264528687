import store from '../Statemanagement/store'; // Adjust the path to your store configuration
import { makeSelectDiagramById } from '../Statemanagement/modelSelectors'; // Adjust the path as necessary
import { dragElement , addElement, deleteElement, addDiagram, hoverConnector, addConnection, addProperty } from '../Statemanagement/modelSlice';
import { setDiagramData } from '../Statemanagement/diagramSlice';
import axios from '../../axiosConfig';

export function dragDiagramElement(diagramId, elementId, offsetX, offsetY){
    store.dispatch(dragElement({ diagramId, elementId, xpos: offsetX, ypos: offsetY }));
}

export function setNewDiagram(diagramId, diagramName, diagramNotation, diagramType){
    store.dispatch(addDiagram({ diagramId: diagramId, diagramName: diagramName, diagramNotation: diagramNotation, diagramType: diagramType }));
}

export function setDiagramInfo(diagramId, diagramName, diagramNotation, diagramType) {
    fetchDiagramData(diagramNotation, diagramType)
        .then(({ elementtypes, connectiontypes }) => {
            store.dispatch(setDiagramData({
                id: diagramId,
                name: diagramName,
                notation: diagramNotation,
                type: diagramType,
                elementtypes: elementtypes,
                connectiontypes: connectiontypes
            }));
        })
        .catch(error => {
            console.error('Error setting diagram info:', error);
        });
}

// Fetch both element types and connection types from a single API endpoint
const fetchDiagramData = async (diagramNotation, diagramType) => {
    try {
        const response = await axios.get(`/api/notations/${diagramNotation}/diagramtype/${diagramType}/data`);
        return response.data;
    } catch (error) {
        console.error('Error fetching diagram data:', error);
        throw error;
    }
};


export function addDiagramElement(diagramId, newDiagramElement, diagramNotation, diagramState, selectedOwner) {
    const elementType = newDiagramElement.type;
    const notationId = diagramNotation; // Assuming notationId is accessible from diagramNotation
    const elementCase = newDiagramElement.case;

    fetchDefaultElementStyle(elementType, diagramState, elementCase)
        .then(elementDefault => {
            store.dispatch(addElement({ 
                diagramId: diagramId, 
                newElement: newDiagramElement, 
                showName: elementDefault.showName,
                elementGeoInfo: elementDefault.geo, 
                elementNotation: notationId,
                elementCase: elementCase,
                elementProperties: elementDefault.properties,
                selectedOwner: selectedOwner,
            }));
        })
        .catch(error => {
            console.error('Error setting element info:', error);
        });
}


async function fetchDefaultElementStyle(elementType, diagramState, elementCase) {
    let elementDefault = {};
    try {
        // Check if the required data is available in the Redux state
        if (diagramState && diagramState.elementtypes && diagramState.elementtypes.length > 0) {
            // Find the element type in the Redux state
            const selectedElement = diagramState.elementtypes.find(element => element.type === elementType);
            if (selectedElement) {
                elementDefault.geo = selectedElement.geo.find(geo => geo.case === elementCase);
                elementDefault.showName = selectedElement.showName;
                elementDefault.properties = selectedElement.properties;
            } else {
                throw new Error(`Element with Type ${elementType} not found in Redux state`);
            }
        } else {
            throw new Error('Diagram state is empty or invalid');
        }

        return elementDefault;
    } catch (error) {
        console.error('Error fetching diagram element data:', error);
        return null;
    }
}

export function addConnectionToDiagram(diagramId, newConnection, diagramState) {
    const connectionType = newConnection.connectionType;
    
    fetchDefaultConnectionStyle(connectionType, diagramState)
        .then(connectionDefault => {
            store.dispatch(addConnection({
                diagramId: diagramId, 
                newConnection: newConnection,
                newConnectionDefault: connectionDefault,
            }));
        })
        .catch(error => {
            console.error('Error setting element info:', error);
        });
}

async function fetchDefaultConnectionStyle(connectionType, diagramState) {
    let connectionDefault = {
        geo : {},
        linetype: 'none'
    };


    try {
        // Check if the required data is available in the Redux state
        if (diagramState && diagramState.connectiontypes && diagramState.connectiontypes.length > 0) {
            // Find the element type in the Redux state
            const selectedElement = diagramState.connectiontypes.find(element => element.type === connectionType);
            if (selectedElement) {
                connectionDefault.geo = selectedElement.geo;
                connectionDefault.linetype = selectedElement.linetype;
            } else {
                throw new Error(`Connection with Type ${connectionType} not found in Redux state`);
            }
        } else {
            throw new Error('Diagram state is empty or invalid');
        }

        return connectionDefault;
    } catch (error) {
        console.error('Error fetching diagram connection data:', error);
        return null;
    }
}


export function deleteDiagramElement(diagramId, selectedElementId) {
    store.dispatch(deleteElement({ diagramId: diagramId, elementId: selectedElementId}));
}

export async function updateDiagramModel(modelId, diagramId) {
    try {
        // Get the updated diagram data from the Redux store
        const selectDiagram = makeSelectDiagramById();
        const diagram = selectDiagram(store.getState(), diagramId);

        if (diagram) {
            // Send the updated diagram data to the server
            const response = await axios.put(`/api/models/${modelId}/diagrams/${diagramId}`, diagram);
            if (response.status === 200) {
                console.log(`Diagram model with ID ${diagramId} updated successfully`);
            } else {
                throw new Error('Failed to update diagram model');
            }
        } else {
            console.error(`Diagram with ID ${diagramId} not found in Redux store`);
        }
    } catch (error) {
        console.error('Error updating diagram model:', error);
    }
}

export async function deleteDiagram(modelId, diagramId) {
    try {
        // Send the DELETE request to the server
        const response = await axios.delete(`/api/models/${modelId}/diagrams/${diagramId}`);
        if (response.status === 200) {
            console.log(`Diagram with ID ${diagramId} deleted successfully`);
            // You might want to dispatch an action to update your Redux store here
        } else {
            throw new Error('Failed to delete diagram');
        }
    } catch (error) {
        console.error('Error deleting diagram:', error);
    }
}

// Function to calculate the distance between two points
function getDistance(x1, y1, x2, y2) {
    return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
  }
  
export function checkForConnectors(diagramId, hoveringElementInfo, x, y) {
    const selectDiagram = makeSelectDiagramById();
    const diagram = selectDiagram(store.getState(), diagramId);

    if (!diagram || !hoveringElementInfo) return;

    const elementId = hoveringElementInfo.id;
    const element = diagram.elements.find(el => el.id === elementId);

    if (!element || !element.connectors) return;

    const connectors = Array.isArray(element.connectors) ? element.connectors : Object.values(element.connectors);

    const hoverThreshold = 10; // Define the distance threshold for hovering

    for (let connector of connectors) {
        const distance = getDistance(x, y, connector.x, connector.y);
        if (distance <= hoverThreshold) {
            store.dispatch(hoverConnector({
                diagramId,
                elementId,
                connectorId: connector.id,
            }));
            return connector;
        }
    }
}

//funtion to convert canvas to base64 image string
export function getCanvasImage(canvas) {
    return canvas.toDataURL("image/png");
  }

  export function getRelatedPosition(ownerPosition, ownerGeo, addedPosition) {
    const ownerHalfWidth = ownerGeo.width / 2;
    const ownerHalfHeight = ownerGeo.height / 2;
    const rect = {x: ownerPosition.x, y: ownerPosition.y, width: ownerGeo.width, height: ownerGeo.height}
    let newpos = {x: 0, y:0}

    const closestBorder = getClosestBorder(addedPosition, rect);

    if(closestBorder === 'top'){
        newpos = {x: addedPosition.x, y: ownerPosition.y - ownerHalfHeight}
    }else if(closestBorder === 'right'){
        newpos = {x: ownerPosition.x + ownerHalfWidth, y: addedPosition.y}
    }else if(closestBorder === 'bottom'){
        newpos = {x: addedPosition.x, y: ownerPosition.y + ownerHalfHeight}
    }else if(closestBorder === 'left'){
        newpos = {x: ownerPosition.x - ownerHalfWidth, y: addedPosition.y}
    }else{
        console.log("problemo")
    }

    const relatedPosition = {x: newpos.x, y: newpos.y};

  
    return relatedPosition;
  }
  

  function getClosestBorder(position, rect) {
    // Destructure position and rectangle for easier access
    const { x: posX, y: posY } = position;
    const { x: rectX, y: rectY, width, height } = rect;
  
    // Calculate the borders of the rectangle
    const left = rectX - width/2;
    const right = rectX + width/2;
    const top = rectY - height/2;
    const bottom = rectY + height/2;
  
    // Calculate distances to each border
    const distanceToLeft = Math.abs(posX - left);
    const distanceToRight = Math.abs(posX - right);
    const distanceToTop = Math.abs(posY - top);
    const distanceToBottom = Math.abs(posY - bottom);
  
    // Find the minimum distance and corresponding border
    const minDistance = Math.min(distanceToLeft, distanceToRight, distanceToTop, distanceToBottom);
  
    let closestBorder;
    if (minDistance === distanceToLeft) {
      closestBorder = 'left';
    } else if (minDistance === distanceToRight) {
      closestBorder = 'right';
    } else if (minDistance === distanceToTop) {
      closestBorder = 'top';
    } else {
      closestBorder = 'bottom';
    }
  
    return closestBorder;
  }

  export function setNewOwnerProperty(diagramId, elementOwner, elementId, elementType) {
    // Select the diagram from the state
    const selectDiagram = makeSelectDiagramById();
    const diagram = selectDiagram(store.getState(), diagramId);
    if (!diagram) {
      console.error(`Diagram with ID ${diagramId} not found.`);
      return false;
    }
  
    // Find the owner element within the diagram
    const owner = diagram.elements.find(element => element.id === elementOwner);
    if (!owner) {
      console.error(`Element with ID ${elementOwner} not found.`);
      return false;
    }
  
    // Check if the elementType is a valid property type for the elementOwner
    const isValidPropertyType = owner.properties.some(property => property.type === elementType);
  
    if (isValidPropertyType) {
      // Dispatch the action to add the property
      store.dispatch(addProperty({
        diagramId,
        elementOwner,
        elementId,
        elementType
      }));
      return true;
    } else {
      console.error("Not a valid property of the selected element");
      return false;
    }
  }