import React from 'react';

const ContentTable = ({ title, content }) => {
    return (
        <table style={styles.table}>
            <thead>
                <tr>
                    <th style={styles.tableHeader}>{title}</th>
                </tr>
            </thead>
            <tbody>
                {content.map((item, index) => (
                    <tr key={index}>
                        <td style={styles.tableData}>{item}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const styles = {
    table: {
        width: '150px',
        borderCollapse: 'collapse',
        margin: '20px',
    },
    tableHeader: {
        backgroundColor: '#292929',
        borderBottom: '1.5px solid #ddd',
        padding: '10px',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: '10px',
        fontWeight: 'bold',
        borderTopLeftRadius: '8px', // Adding corner radius to the top-left
        borderTopRightRadius: '8px', // Adding corner radius to the top-right
    },
    tableData: {
        backgroundColor: '#444444',
        borderBottom: '0.5px solid #ddd',
        padding: '10px',
        textAlign: 'left',
        fontSize: '10px',
    },
};

export default ContentTable;
