import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import FrameworkDiagramPreview from './FrameworkDiagramPreview';
import HomeLink from '../HomeLink';
import axios from '../../../axiosConfig';

const FrameworkOverview = () => {
  const [framework, setFramework] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();

  const fetchFrameworkById = async (frameworkId) => {
    try {
      const response = await axios.get(`/api/framework/${frameworkId}`);
      console.log("fetched:", response)
      return response.data;
    } catch (error) {
      console.error('Error fetching framework:', error);
      throw error;
    }
  };

  useEffect(() => {
    const frameworkId = id;
    const fetchFramework = async () => {
      try {
        const response = await fetchFrameworkById(frameworkId);
        console.log("notations:", response)
        setFramework(response);
      } finally {
        setLoading(false);
      }
    };

    fetchFramework();
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      <h1>{framework.name}</h1>
      <h3>Framework Overview</h3>
      <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {framework && Object.keys(framework.diagrams).map(elementKey => (
          <FrameworkDiagramPreview 
          key={elementKey} 
          element={framework.diagrams[elementKey]} 
          stakeholder={framework.stakeholder}
          concerns={framework.concerns}
          ontology={framework.ontology}
          viewpoints={framework.viewpoints}
          />
        ))}
      </div>
      <HomeLink />
    </div>
  );
};

export default FrameworkOverview;
