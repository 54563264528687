//This component is an endless canvas with grid

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {drawDiagram, handleCanvasClick, handleCanvasHover} from './drawUtils.js'; // Import the drawUtils module
import {dragDiagramElement,checkForConnectors, addConnectionToDiagram} from './modelUtils.js'; // Import the drawUtils module
import DiagramNameTag from './DiagramNameTag.jsx';

import { v4 as uuidv4 } from 'uuid';

function generateUniqueId() {
  return uuidv4();
}

function DiagramCanvasRework({ setSelectedOwner, updateInfoText , windowOrigin , canvasOrigin , setCanvasOrigin,  allowPan, addElement, setAddElement, addElementMode, setAddElementMode, newElementPosition, setNewElementPosition, onSelect, addConnectionMode, setAddConnectionMode, selectedShape }) {
  const [canvas, setCanvas] = useState(null);
  const [ctx, setCtx] = useState(null);

  // Use useRef to access DOM elements
  const canvasRef = useRef(null);
  
  // Variables to track panning
  const [isPanningCanvas, setIsPanningCanvas] = useState(false);
  const [startPanX, setStartPanX] = useState(0);
  const [startPanY, setStartPanY] = useState(0);
  const [deltaPanX, setDeltaPanX] = useState(0);
  const [deltaPanY, setDeltaPanY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedElement, setSelectedElement] = useState(0);
  const [selectedConnector, setSelectedConnector] = useState(0);
  const [connectorStart, setConnectorStart] = useState(null);

  let delx = useState(0);
  let dely = useState(0);

  const { diagramId } = useParams();
  const diagram = useSelector(state => state.model.diagrams.find(diagram => diagram.id === diagramId)); // Fetch diagram based on ID from Redux state
  const diagramElements = diagram.elements;
  const connectionElements = diagram.connections;
  const diagramState = useSelector(state => state.diagram); // Access the diagram state from Redux


  // Initialize canvas origin
  useEffect(() => {
    const originx = window.innerWidth / 2;
    const originy = window.innerHeight / 2;
    windowOrigin.x = originx;
    windowOrigin.y = originy;
    canvasOrigin.x = originx;
    canvasOrigin.y = originy;
    updateInfoText('Ready');
  }, []);


   // Initialize canvas and context, and handle window resize
   useEffect(() => {
    const canvasElement = canvasRef.current;
    const context = canvasElement.getContext('2d');
    setCanvas(canvasElement);
    setCtx(context);

    const resizeCanvas = () => {
      const devicePixelRatio = window.devicePixelRatio || 1;
      const canvasWidth = window.innerWidth;
      const canvasHeight = window.innerHeight;

      const originx = canvasWidth * devicePixelRatio / 4;
      const originy = canvasHeight * devicePixelRatio / 4;

      windowOrigin.x = originx;
      windowOrigin.y = originy;

      canvasElement.width = canvasWidth * devicePixelRatio;
      canvasElement.height = canvasHeight * devicePixelRatio;

      context.scale(devicePixelRatio, devicePixelRatio);

      context.clearRect(0, 0, canvasElement.width, canvasElement.height);
      drawDiagram(canvasElement, delx, dely, context, windowOrigin, canvasOrigin, diagramElements, connectionElements, addConnectionMode );
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []); // Runs only once after the initial render

  // Draw diagram whenever images are loaded or diagram elements change
  useEffect(() => {
    if (ctx) {
      drawDiagram(canvas, delx, dely, ctx, windowOrigin, canvasOrigin, diagramElements, connectionElements, addConnectionMode );
    }
  },); // Runs when imagesLoaded, ctx, diagramElements, or connectionElements change


    
  // Mouse Down Eventlistener
  const handleCanvasMouseDown = (event) => {
    let elClicked = false;
    let conClicked = false;
    let possibleOwner = false;
    const newId = generateUniqueId();

    if (addConnectionMode) {
      if (!connectorStart && selectedConnector) {
          // First click: Set the start connector
          setConnectorStart(selectedConnector);
          updateInfoText('Start connector selected');
      } else if (connectorStart && selectedConnector && selectedConnector !== connectorStart) {
          // Second click: Set the end connector and create the connection
          if (selectedConnector && selectedConnector.element && selectedConnector.connector) {
              const newConnection = {
                  id: newId, 
                  elementstart: connectorStart.element.id,
                  connectorstart: connectorStart.connector.id,
                  elementend: selectedConnector.element.id,
                  connectorend: selectedConnector.connector.id,
                  connectionType: selectedShape,
              };
              addConnectionToDiagram(diagramId, newConnection, diagramState);
              setConnectorStart(null);  // Reset connectorStart
              setAddConnectionMode(false);  // Exit add connection mode
              updateInfoText('Connection added');
          } else {
              setConnectorStart(null);  // Reset connectorStart
              setAddConnectionMode(false);  // Exit add connection mode
              updateInfoText('Canceled adding connection');
          }
      } else if (!selectedConnector) {
          // Cancel: No connector selected
          setConnectorStart(null);  // Reset connectorStart
          setAddConnectionMode(false);  // Exit add connection mode
          updateInfoText('Canceled adding connection');
      }
    }

    if (!addConnectionMode){
    const { elementClicked, connectionClicked, selectedElementInfo } = handleCanvasClick(event, canvas, canvasOrigin, diagramElements, connectionElements, diagramId);
    setSelectedElement(selectedElementInfo)
    possibleOwner = selectedElementInfo;
    handleSelection(selectedElementInfo)
    elClicked = elementClicked;
    conClicked = connectionClicked;
    }

    if (elClicked) {
      if(addElementMode){
        setSelectedOwner(possibleOwner)
        setAddElement(true);
        setNewElementPosition({x: -(canvasOrigin.x-event.clientX), y: -(canvasOrigin.y-event.clientY)});
      }else{
      setIsDragging(true);
      setStartPanX(event.clientX - canvasOrigin.x); //missused for dragging
      setStartPanY(event.clientY - canvasOrigin.y); //missused for dragging
      }
    } else if(conClicked){
      updateInfoText('connection selected')
    }else{    
      if(addElementMode){
        setAddElement(true);
        setNewElementPosition({x: -(canvasOrigin.x-event.clientX), y: -(canvasOrigin.y-event.clientY)});
      }else if(allowPan & !addElementMode){    
        setIsPanningCanvas(true); // trigger panning mode
        setStartPanX(event.clientX); //??
        setStartPanY(event.clientY); //??
        setDeltaPanX(event.clientX); //??
        setDeltaPanY(event.clientY); //??
      }
    }
  };

  // Mouse Move Eventlistener
  const handleCanvasMouseMove = (event) => {

    if (isDragging) {

      const elementOffset = {
        x: selectedElement.position.x - startPanX,
        y: selectedElement.position.y - startPanY
      };
      const offsetX = event.clientX - canvasOrigin.x + elementOffset.x;
      const offsetY = event.clientY - canvasOrigin.y + elementOffset.y;
      updateInfoText('element dragging')
      dragDiagramElement(diagramId, selectedElement.id, offsetX, offsetY)
    }

    if (addConnectionMode) {
      // Get the mouse coordinates relative to the canvas
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left - canvasOrigin.x;
      const y = event.clientY - rect.top - canvasOrigin.y;

      // Check if the cursor is on an element and set it to the hover state
      const hovering = handleCanvasHover(event, canvas, canvasOrigin, diagramElements, diagramId);
      if(hovering.elementHover){
        const connector = checkForConnectors(diagramId, hovering.hoveringElementInfo, x, y)
        setSelectedConnector({ element: hovering.hoveringElementInfo, connector });
      }else{
        setSelectedConnector(null)
      }
    }

    if (isPanningCanvas) {
    const deltaX = startPanX - event.clientX;
    const deltaY = startPanY - event.clientY;

    // Move canvas in the opposite direction of the cursor movement
    setCanvasOrigin((prevOrigin) => ({
      x: prevOrigin.x - deltaX,
      y: prevOrigin.y - deltaY,
    }));

    // Adjust the startPanX and startPanY for continuous panning
    setStartPanX(event.clientX);
    setStartPanY(event.clientY);
    updateInfoText(`pan on canvas`);
    }
  };

  // Mouse Up Eventlistener
  const handleCanvasMouseUp = (event) => { 

    if(isDragging){
      setIsDragging(false)
    }
    // Update active framework element position after dragging
    if(isPanningCanvas){
      const delpanx = startPanX - deltaPanX;
      const delpany = startPanY - deltaPanY;
      setDeltaPanX(delpanx);
      setDeltaPanY(delpany);
    }
    // Stop panning

    if(selectedElement){
      updateInfoText('element selected');
    }else{
    setIsPanningCanvas(false);
      updateInfoText('IDLE');
    }
  };

  const handleSelection = (info) => {
    // Simulate getting selected element info
    const selectedElementInfo = info;
    // Call the onSelect function passed from the parent component
    onSelect(selectedElementInfo);
  };


  return (
    <div> 
      <canvas 
      ref={canvasRef} 
      id="canvas" 
      className="canvas-container" 
      onMouseDown={handleCanvasMouseDown} 
      onMouseMove={handleCanvasMouseMove} 
      style={{ cursor: isDragging ? 'move' : isPanningCanvas ? 'grabbing': 'default' }}
      onMouseUp={handleCanvasMouseUp}
      ></canvas> 
      <DiagramNameTag name={diagram.name} type={diagram.type}/>
    </div>
  );
}


export default (DiagramCanvasRework);