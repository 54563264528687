import React from "react";
import { Link } from "react-router-dom";

const LibraryEntry = ({ item }) => {
  return (
    <Link className="library-element" to={`/${item.type}/${item._id}`}>
      <div className="link-text">
        {item.name}
      </div>
    </Link>
  );
};

export default LibraryEntry;
