import React from 'react';
import './Menu.css';

const TitleBox = ({ setContactSelected, setAboutSelected }) => {
    // Click handler for the "About" and "Contact" links
    const handleNavClick = (e, section) => {
        e.preventDefault(); // Prevent default anchor behavior
        if (section === 'about') {
            setAboutSelected(true);
            setContactSelected(false);
        } else if (section === 'contact') {
            setAboutSelected(false);
            setContactSelected(true);
        }
    };

    return (
        <div className='titlebox'>
            <div className='titlebox-content'>
                <div className='titlebox-left'>
                    <nav className='menu'>
                        <ul>
                            <li><a href='#'></a></li>
                        </ul>
                    </nav>
                </div>
                <div className='titlebox-center'>
                    <img 
                        src='../../modela_logo_text.png' 
                        alt='Modela Logo' 
                        style={{ width: '200px', height: 'auto' }} // Adjust width as needed
                    />
                </div>
                <div className='titlebox-right'>
                </div>
            </div>
        </div>
    );
}

export default TitleBox;
