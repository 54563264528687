//This component uses an endless canvas component and adds a toolbar with addtional functions to control panning and centering the canvas.

import React, { useState } from 'react';

import DiagramCanvas from './DiagramCanvasRework';
import HomeLink from '../Menu/HomeLink';
import DiagramCanvasToolbar from './DiagramCanvasToolbar';

function DiagramViewer({ setSelectedOwner, updateInfoText, addElementMode, setAddElementMode, setAddConnectionMode, addElement, setAddElement, newElementPosition, setNewElementPosition, onSelect, addConnectionMode, selectedShape }) {

  const [windowOrigin, setWindowOrigin] = useState({ x: 0, y: 0 });
  const [canvasOrigin, setCanvasOrigin] = useState({ x: 0, y: 0 });

  const [allowPan, setAllowPan] = useState(true);

  return (
    <div id="diagram-viewer" className="diagram-view-container">
      <div>
        <DiagramCanvas
          updateInfoText={updateInfoText}
          windowOrigin={windowOrigin}
          canvasOrigin={canvasOrigin} 
          setCanvasOrigin={setCanvasOrigin} 
          allowPan={allowPan}
          addElementMode={addElementMode} 
          setAddElementMode={setAddElementMode} 
          addElement={addElement}
          setAddElement={setAddElement}
          newElementPosition={newElementPosition}
          setNewElementPosition={setNewElementPosition}
          onSelect={onSelect}
          addConnectionMode ={addConnectionMode}
          setAddConnectionMode ={setAddConnectionMode}
          selectedShape={selectedShape}
          setSelectedOwner={setSelectedOwner}
        />
        <DiagramCanvasToolbar
          windowOrigin={windowOrigin}
          canvasOrigin={canvasOrigin} 
          setCanvasOrigin={setCanvasOrigin} 
          allowPan={allowPan}
          setAllowPan={setAllowPan}
        />
        <HomeLink/>
      </div>
    </div>
  );
}

export default DiagramViewer;

