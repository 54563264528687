import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-bottom">
                <p>&copy; 2024 mylview. All rights reserved.</p>
            </div>
            <div className="footer-bottom-right">
                <p>v0.1.0 pre-alpha</p>
            </div>
        </footer>
    );
}

export default Footer;
